import React, { useState, useEffect } from 'react'

import Loading from '../components/loading'
import Head from '../components/head'
import AdminService from '../components/admin-service'
import NewService from '../components/new-service'
import EditorService from '../components/editor-service'
import RegisterService from '../components/register-service'
import NotLoggedInService from '../components/not-logged-in-service'
import Modal from '../components/modal'
import Markdown from '../components/markdown'
import TimesIcon from '../components/icons/times-icon'
import TrashIcon from '../components/icons/trash-icon'

const Services = ({ services, save, reload, approve, decline, register, leave, removeUser, removeService, changeRole, invite }) => (
  <div>
    {services.map(service => {
      if (service.role === 'admin') return <AdminService key={`${service.name}-${service.time}`} {...service} save={save} reload={reload} approve={approve} decline={decline} leave={leave} removeUser={removeUser} removeService={removeService} changeRole={changeRole} invite={invite} />
      if (service.role === 'editor') return <EditorService key={service.name} {...service} leave={leave} />
      if (service.role === 'request') return <RegisterService key={service.name} {...service} registered leave={leave} />
      return <RegisterService key={service.name} register={register} {...service} />
    })}
  </div>
)

const ServiceModal = ({ dismiss, active, leave, remove, user, service, api }) => {
  let title = ''
  if (leave) title = `Leave ${service}?`
  if (remove) title = `Remove ${service}?`
  if (user) title = `Remove ${user} from ${service}?`

  let onClick = () => {}
  let label = ''
  if (leave) {
    label = 'LEAVE'
    onClick = () => { api.emit('leave-service', service) }
  }
  if (remove) {
    label = 'REMOVE'
    onClick = () => { api.emit('remove-service', service) }
  }
  if (user) {
    label = 'REMOVE'
    onClick = () => { api.emit('update-user-service', { service, name: user, remove: true }) }
  }

  let content = ''
  if (leave) content = 'Are you sure you wish to remove this? This action can not be undone.'
  if (remove) content = 'Are you sure you wish to leave? This action can not be undone.'
  if (user) content = `Are you sure you wish to remove **${user}** from this service? This action can not be undone`

  return (
    <Modal
      onDismiss={dismiss}
      active={active}
      title={title}
      buttons={[{
        label,
        danger: true,
        icon: <TrashIcon />,
        onClick
      }, {
        label: 'CANCEL',
        icon: <TimesIcon />,
        onClick: dismiss
      }]}
    >
      <Markdown>{content}</Markdown>
    </Modal>
  )
}

export default ({ profile, api }) => {
  const [services, setServices] = useState([])
  const [modal, setModal] = useState({ active: false })

  const dismissModal = () => setModal({ active: false })
  const openModal = ({ leave, remove, user, service }) => setModal({ active: true, leave, remove, user, service })

  useEffect(() => {
    document.title = 'Services'

    const onGetUserServicesReply = (services) => {
      setServices(services)
    }
    api.on('get-user-services-reply', onGetUserServicesReply)

    const onConnet = () => {
      api.emit('get-user-services')
      dismissModal()
    }
    api.on('connect', onConnet)

    const onServicesUpdated = () => {
      api.emit('update-user')
      dismissModal()
    }
    api.on('services-updated', onServicesUpdated)

    const onUserUpdated = () => {
      api.emit('get-user-services')
      dismissModal()
    }
    api.on('user-updated', onUserUpdated)
    if (profile.loggedIn && services.length === 0) api.emitUntilResponse('get-user-services', {}, 'get-user-services-reply')
    return () => {
      api.off('get-user-services-reply', onGetUserServicesReply)
      api.off('connect', onConnet)
      api.off('services-updated', onServicesUpdated)
      api.off('user-updated', onUserUpdated)
    }
  })

  return (
    <div>
      <ServiceModal {...modal} dismiss={dismissModal} api={api} />
      <Head label='Services' />
      <div className='service'>
        {profile.loggedIn && services.length === 0
          ? <Loading>Finding available services ...</Loading>
          : profile.loggedIn ? (
            <Services
              services={services}

              removeService={(service) => { openModal({ remove: true, service }) }}
              leave={(service) => { openModal({ leave: true, service }) }}
              removeUser={(service, user) => { openModal({ user, service }) }}

              save={({ original, name, url, token, networkId }) => { api.emit('save-service', { original, name, url, token, networkId }) }}
              reload={({ name }) => { api.emit('reload-service', { name }) }}
              register={(service, role) => { api.emit('register-service', { service, role }) }}

              approve={({ service, name }) => { api.emit('update-user-service', { service, name, role: 'editor', type: 'approve' }) }}
              decline={({ service, name }) => { api.emit('update-user-service', { service, name, remove: true, type: 'remove' }) }}
              changeRole={(service, role, name) => { api.emit('update-user-service', { service, name, role, type: 'change' }) }}
              invite={(service, name) => { api.emit('update-user-service', { service, name, role: 'editor', type: 'invite' }) }}
            />
          ) : <NotLoggedInService />}
        {profile.loggedIn ? <NewService save={({ name, url, token, networkId }) => { api.emit('save-service', { name, url, token, networkId }) }} /> : null}
      </div>
    </div>
  )
}
