import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='warning'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M20 10C20 15.5229 15.5228 20 10 20C4.47717 20 0 15.5229 0 10C0 4.47715 4.47717 0 10 0C15.5228 0 20 4.47715 20 10ZM18.0953 10C18.0953 14.4709 14.4709 18.0952 10 18.0952C5.52917 18.0952 1.90479 14.4709 1.90479 10C1.90479 5.52912 5.52917 1.90476 10 1.90476C14.4709 1.90476 18.0953 5.52912 18.0953 10ZM9.84998 12.432C9.97467 12.5453 10.122 12.602 10.292 12.602C10.462 12.602 10.6037 12.5453 10.717 12.432C10.8417 12.3187 10.9153 12.1543 10.938 11.939L11.567 5.343L11.584 5.156C11.584 4.80467 11.4594 4.527 11.21 4.323C10.972 4.10767 10.666 4 10.292 4C9.91803 4 9.60632 4.10767 9.35699 4.323C9.11902 4.527 9 4.80467 9 5.156L9.01703 5.326L9.646 11.939C9.66864 12.1543 9.73669 12.3187 9.84998 12.432ZM9.39099 15.815C9.62903 16.053 9.92932 16.172 10.292 16.172C10.6547 16.172 10.955 16.053 11.193 15.815C11.431 15.5657 11.55 15.2597 11.55 14.897C11.55 14.5343 11.431 14.234 11.193 13.996C10.955 13.7467 10.6547 13.622 10.292 13.622C9.92932 13.622 9.62903 13.7467 9.39099 13.996C9.15302 14.234 9.034 14.5343 9.034 14.897C9.034 15.2597 9.15302 15.5657 9.39099 15.815Z' />
    </svg>

  </Icon>
)
