import React, { useState } from 'react'
import merge from 'merge'

import InputText from './input-text'
import Button from './button'
import TimesIcon from './icons/times-icon'
import PlusIcon from './icons/plus-icon'
import Panel from './panel'
import WarningIcon from './icons/warning-icon'
import { checkFieldnameMetadata } from '../validators'

const FieldnameMetaData = ({ data, update, disabled, remove }) => {
  const [newFieldname, setNewFieldname] = useState('')
  return (
    <div className='fieldname-meta-data'>
      <p className='page-title'>Fieldname Meta Data</p>
      <div className='metadata'>
        {data.map((d, index) => {
          const updateValue = (value) => {
            data[index] = merge(true, data[index], value)
            update([].concat(data))
          }

          const errors = {}
          const fieldnameMetadataErrors = checkFieldnameMetadata(d)
          if (fieldnameMetadataErrors !== true) {
            fieldnameMetadataErrors.forEach(({ field, message }) => {
              errors[field] = message
            })
          }

          return (
            <div className='columns' key={`data-${index}`}>
              <div className='column is-10'>
                <Panel
                  open={fieldnameMetadataErrors !== true}
                  icon={fieldnameMetadataErrors !== true ? <WarningIcon /> : null}
                  label={`Fieldname: ${d.fieldname}${d.key ? `, Key: ${d.key}` : ''}`}
                >
                  <InputText name='fieldname' label='Fieldname' placeholder='Fieldname' value={d.fieldname} onChange={(fieldname) => updateValue({ fieldname })} message={errors.fieldname} />
                  <InputText name='observed preference label' label='Observed Preference Label' placeholder='Observed Preference Label' value={d.observedPreferenceLabel} onChange={(observedPreferenceLabel) => updateValue({ observedPreferenceLabel })} message={errors.observedPreferenceLabel} />
                  <InputText name='output' label='Output' placeholder='Output' value={d.output} onChange={(output) => updateValue({ output })} message={errors.output} />
                  <InputText name='observable property' label='Observable Property' placeholder='Observable Property' value={d.observableProperty} onChange={(observableProperty) => updateValue({ observableProperty })} message={errors.observableProperty} />
                  <InputText name='units' label='Units' placeholder='Units' value={d.units} onChange={(units) => updateValue({ units })} message={errors.units} />
                  <InputText name='key' label='Key' placeholder='Key' value={d.key} onChange={(key) => updateValue({ key })} message={errors.key} />
                </Panel>
              </div>
              <div className='column is-2'>
                <Button danger onClick={() => { remove(index) }}>
                  <TimesIcon />
                  <span>REMOVE</span>
                </Button>
              </div>
            </div>
          )
        })}
      </div>
      <div className='columns'>
        <div className='column is-10'>
          <InputText
            name='new fieldname'
            disabled={disabled}
            value={newFieldname}
            label='Fieldname'
            placeholder='Fieldname'
            onChange={(fieldname) => setNewFieldname(fieldname)}
          />
        </div>
        <div className='column'>
          <Button
            className='metadata-add'
            disabled={disabled}
            onClick={() => {
              if (newFieldname !== '') {
                update(data.concat({ fieldname: newFieldname }))
                setNewFieldname('')
              }
            }}
          >
            <PlusIcon />
            <span>ADD</span>
          </Button>
        </div>
      </div>

    </div>
  )
}

export default FieldnameMetaData
