import React from 'react'

import Title from './title'
import InputText from './input-text'

const SensorForm = ({ service, foi, onChange, errors, nested }) => {
  let id = foi.id || ''
  if (id === '_new_foi') id = ''
  return (
    <div className={`column insert-form ${nested ? 'is-nested' : ''}`} data-tour='information'>
      <Title>Feature Of Interest Information</Title>
      <InputText
        name='id'
        message={errors.id}
        value={id}
        label='ID'
        disabled={service === null}
        onChange={(id) => { onChange({ id }) }}
      />
      <InputText
        name='feature of interest name'
        message={errors.name}
        value={foi.name || ''}
        label='Name'
        disabled={service === null}
        onChange={(name) => { onChange({ name }) }}
      />
      <InputText
        name='feature of interest uri'
        message={errors.uri}
        value={foi.uri || ''}
        label='URI'
        disabled={service === null}
        onChange={(uri) => { onChange({ uri }) }}
      />
      <div className='columns'>
        <div className='column is-half'>
          <InputText
            name='latitude'
            message={errors.lat}
            value={foi.lat || ''}
            label='Latitude'
            disabled={service === null}
            onChange={(lat) => { onChange({ lat }) }}
          />
        </div>
        <div className='column is-half'>
          <InputText
            name='longitude'
            message={errors.lon}
            value={foi.lon || ''}
            label='Longitude'
            disabled={service === null}
            onChange={(lon) => { onChange({ lon }) }}
          />
        </div>
      </div>
    </div>
  )
}
export default SensorForm
