import React from 'react'

export default ({
  label,
  disabled,
  items = [],
  onChange,
  readOnly,
  value = '',
  name
}) => (
  <div className={`field ${disabled ? 'is-disabled' : ''}`}>
    <label htmlFor={name} className='label'>{label}</label>
    <div className='control'>
      <div className='select is-fullwidth'>
        <select id={name} name={name} disabled={disabled} value={value} onChange={(evt) => onChange(evt.target.value)}>
          <option value='' disabled />
          {items.map(({ value, label }, index) => <option key={`${value}-${index}`} value={value}>{label || value}</option>)}
        </select>
      </div>
    </div>
  </div>
)
