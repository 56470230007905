import React, { useState, useEffect } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Cookies from 'js-cookie'
import { create, mock } from './api'

import Modal from './components/modal'
import Markdown from './components/markdown'
import Hero from './components/hero'
import Foot from './components/foot'

import SearchPage from './pages/search-page'
import ServicesPage from './pages/services-page'
import ContactPage from './pages/contact-page'
import PolicyPage from './pages/policy-page'
import InsertSensorPage from './pages/insert-sensor-page'
import ViewSensorPage from './pages/view-sensor-page'
import DataSensorPage from './pages/data-sensor-page'
import UploadSensorPage from './pages/upload-sensor-page'
import InsertFoiPage from './pages/insert-foi-page'
import ViewFoiPage from './pages/view-foi-page'
import TransactionsPage from './pages/transactions-page'
import ThanksPage from './pages/thanks-page'
import CallbackPage from './pages/callback-page'
import BatchUploadPage from './pages/insert-data-page'
import NotFoundPage from './pages/not-found-page'
import HelpPage from './pages/help-page'

import Auth from './auth'
import CheckIcon from './components/icons/check-icon'

const history = createBrowserHistory()

const auth = Auth({
  domain: process.env.REACT_APP_DOMAIN,
  clientID: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_CALLBACK_URL
})

const login = async (profile, setProfile) => {
  const pathname = history.location.pathname
  if (pathname.includes('/callback')) {
    try {
      await auth.handleAuthentication()
      const profile = await auth.getProfile()
      setProfile({ ...profile, init: true })
    } catch (err) {
      if (process.env.NODE_ENV === 'development') console.log(err)
    } finally {
      const returnTo = Cookies.get('returnTo') || '/'
      Cookies.remove('returnTo')
      history.push(returnTo)
    }
  } else if (auth.isLoggedIn() && !auth.isAuthenticated()) {
    await auth.renewSession()
    const profile = await auth.getProfile(CallbackPage)
    setProfile({ ...profile, init: true })
  } else {
    setProfile({ ...profile, init: true })
  }
}

const AcceptDialog = ({ accepted, accept }) => (
  <Modal
    title='Privacy Policy'
    active={navigator.userAgent !== 'ReactSnap' && !accepted}
    buttons={[{
      icon: <CheckIcon />,
      label: 'ACCEPT',
      onClick: accept
    }]}
    onDismiss={accept}
  >
    <Markdown>By continuing to use the eLTER Central Data Node you consent to our usage of cookies. Please see [Privacy Policy](link=/policy) for further details.</Markdown>
  </Modal>
)

const App = ({ profile, api }) => {
  const [accepted, setAccepted] = useState(Cookies.get('accepted') === 'true')
  const accept = () => {
    Cookies.set('accepted', 'true')
    setAccepted(true)
  }
  const unlisten = history.listen(accept)
  useEffect(() => () => { unlisten() })

  return (
    <Router history={history}>
      <div>
        <AcceptDialog accept={accept} accepted={accepted} />
        <Hero login={() => { auth.login('/') }} logout={() => auth.logout()} profile={profile} />
        <main className='body container'>
          <Switch>
            <Route exact path='/' component={props => <SearchPage profile={profile} history={history} api={api} {...props} />} />

            <Route path='/insert/sensor' component={props => <InsertSensorPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/view/sensor' component={props => <ViewSensorPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/data/sensor' component={props => <DataSensorPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/upload/sensor' component={props => <UploadSensorPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/insert/data' component={props => <BatchUploadPage profile={profile} history={history} api={api} {...props} />} />

            <Route path='/insert/foi' component={props => <InsertFoiPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/view/foi' component={props => <ViewFoiPage profile={profile} history={history} api={api} {...props} />} />

            <Route path='/transactions' component={props => <TransactionsPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/services' component={props => <ServicesPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/contact' component={props => <ContactPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/policy' component={props => <PolicyPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/thanks' component={props => <ThanksPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/help' component={props => <HelpPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='/callback' component={props => <CallbackPage profile={profile} history={history} api={api} {...props} />} />
            <Route path='*' component={props => <NotFoundPage profile={profile} history={history} api={api} {...props} />} />
          </Switch>
        </main>
        <Route path='*' component={props => <Foot profile={profile} history={history} api={api} {...props} />} />
      </div>
    </Router>
  )
}

export default () => {
  const [profile, setProfile] = useState({ nickname: 'Login', name: '', init: false, loggedIn: false })
  if (navigator.userAgent !== 'ReactSnap' && !profile.init) login(profile, setProfile)

  let api = mock()
  if (profile.init) api = create(auth, profile)
  return <App profile={profile} api={api} />
}
