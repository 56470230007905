import React from 'react'

import InputText from './input-text'
import SearchIcon from './icons/search-icon'
import Button from './button'
import Loading from './loading'

import MapPointIcon from './icons/map-point-icon'
import SensorIcon from './icons/sensor-icon'

const AutoSuggest = ({ onChange = () => {}, suggestions = [], loading = false, value }) => (
  <div className='auto-suggest column'>
    <InputText name='search' label='Search' icon={<SearchIcon />} onChange={onChange} value={value} tour='search' />
    <div className='suggestions'>
      {loading ? <Loading>Searching ...</Loading> : null}
      {suggestions.map(suggestion => (
        <Button
          to={`view/${suggestion.type}?id=${suggestion.id}&service=${suggestion.service}`}
          className={`suggestion is-${suggestion.type}`}
          key={`${suggestion.service}-${suggestion.type}-${suggestion.id}`}
          disabled={suggestion.disabled}
        >
          {suggestion.type === 'sensor' ? <SensorIcon /> : <MapPointIcon />}
          <span>{suggestion.service} - {suggestion.id}</span>
        </Button>
      )
      )}
    </div>
  </div>
)

export default AutoSuggest
