import React, { useState } from 'react'
import ChevronIcon from './icons/chevron-icon'

const Panel = ({ label, icon = null, children, open = false }) => {
  const [active, setActive] = useState(open)
  return (
    <div className={`panel card ${active ? 'is-active' : 'is-inactive'}`}>
      <button className='card-header' onClick={() => setActive(!active)}>
        <p className='card-header-title'>
          {icon}
          <span className='text'>{label}</span>
        </p>
        <span className='card-header-icon' aria-label='more options'>
          <span className='icon'>
            <ChevronIcon />
          </span>
        </span>
      </button>
      <div className='card-content'>
        {active ? children : null}
      </div>
    </div>
  )
}

export default Panel
