import React, { useState } from 'react'
import merge from 'merge'

import Title from './title'
import InputText from './input-text'
import Button from './button'
import SaveIcon from './icons/save-icon'
import TrashIcon from './icons/trash-icon'
import SpinnerIcon from './icons/spinner-icon'
import UploadIcon from './icons/upload-icon'
import TimesIcon from './icons/times-icon'
import CheckIcon from './icons/check-icon'
import Buttons from './buttons'
import EmailIcon from './icons/email-icon'
import CogIcon from './icons/cog-icon'
import ProfileIcon from './icons/profile-icon'
import ValidListIcon from './icons/valid-list-icon'
import InvalidListIcon from './icons/invalid-list-icon'
import WanringIcon from './icons/warning-icon'
import ReloadIcon from './icons/reload-icon'
import Panel from './panel'
import ServiceName from './service-name'
import ServiceNetworkId from './service-network-id'
import ServiceUrl from './service-url'
import ServiceToken from './service-token'

import { checkEmail, checkService } from '../validators'

const UpdateButton = ({ service, canSave, setService, save }) => (
  <div className='columns'>
    <div className='column' />
    <div className='column is-4'>
      <Button
        className={service.saving ? 'is-saving' : ''}
        disabled={canSave}
        onClick={async () => {
          setService(merge(true, service, { saving: true }))
          await save(service)
          setService(merge(true, service, { saving: false, changed: false, title: service.name }))
        }}
      >
        {service.saving ? <SpinnerIcon /> : <SaveIcon />}
        <span>{!service.saving ? 'UPDATE' : 'SAVING'}</span>
      </Button>
    </div>
  </div>
)

const Options = ({ service, reload, removeService, leave }) => {
  return (
    <div>
      <div className='columns service-options'>
        <div className='column' />
        <div className='column is-2'>
          <Button onClick={() => { reload(service) }}>
            <ReloadIcon />
            <span>RELOAD</span>
          </Button>
        </div>
        <div className='column is-2'>
          <Button danger onClick={() => removeService(service.name)}>
            <TrashIcon />
            <span>REMOVE</span>
          </Button>
        </div>
        <div className='column is-2'>
          <Button danger className='is-leave' onClick={() => leave(service.name)}>
            <UploadIcon />
            <span>LEAVE</span>
          </Button>
        </div>
      </div>
      {service.time ? (
        <p className='content has-text-right'>
          {`Last changed ${new Date(service.time).toISOString()}`}
        </p>
      ) : null}
    </div>
  )
}

const User = ({ name, role, service, removeUser, changeRole }) => (
  <div className='columns'>
    <div className='column is-hidden-tablet'>
      <p className='content services-username'>{name}</p>
    </div>
    <div className='column is-2'>
      <Buttons
        onChange={(value) => { changeRole(service, value, name) }}
        value={role}
        items={[{ label: 'ADMIN', value: 'admin' }, { label: 'EDITOR', value: 'editor' }]}
      />
    </div>
    <div className='column is-2'>
      <Button danger onClick={() => removeUser(service, name)}>
        <TrashIcon />
        <span>REMOVE</span>
      </Button>
    </div>
    <div className='column is-hidden-mobile'>
      <p className='content services-username'>{name}</p>
    </div>
  </div>
)

const Request = ({ service, name, approve, decline }) => (
  <div className='columns'>
    <div className='column is-2'>
      <Button onClick={() => approve({ service, name })}>
        <CheckIcon />
        <span>APPROVE</span>
      </Button>
    </div>
    <div className='column is-2'>
      <Button danger onClick={() => decline({ service, name })}>
        <TimesIcon />
        <span>DECLINE</span>
      </Button>
    </div>
    <div className='column'>
      <p className='content services-username'>{name}</p>
    </div>
  </div>
)

const Invite = ({ invite, service }) => {
  const [email, setEmail] = useState('')
  const error = checkEmail({ email })
  return (
    <div className='columns service-invite'>
      <div className='column is-10'>
        <InputText
          name='email'
          value={email}
          onChange={(value) => { setEmail(value) }}
          placeholder='email@address.com'
          icon={<EmailIcon />}
          message={email !== '' && error !== true ? error[0].message : ''}
        />
      </div>
      <div className='column is-2'>
        <Button
          disabled={error !== true} onClick={() => {
            setEmail('')
            invite(service, email)
          }}
        >
          <EmailIcon />
          <span>INVITE</span>
        </Button>
      </div>
    </div>
  )
}

const Status = ({ valid, title, message, endpoints = [] }) => (
  <div className='service-status'>
    <div className='service-status-title'>
      <p className='content'>{valid ? <CheckIcon /> : <TimesIcon />}{title}</p>
    </div>
    {!valid ? <p className='service-status-message'>{message}</p> : null}
    {endpoints.length > 0 ? <p className='service-status-stack'>Inactive Endpoints: {endpoints.filter(value => value !== '').join(', ')}</p> : null}
  </div>
)

const AdminService = ({
  original,
  time = 0,
  name,
  networkId,
  url,
  token,
  save,
  reload,
  users = {},
  approve,
  decline,
  leave,
  removeUser,
  removeService,
  changeRole,
  invite,
  valid
}) => {
  const [service, setService] = useState({ original, time, name, networkId, url, token, changed: false, saving: false, title: name, saved: false, valid })
  const errors = { name: null, url: null, networkId: null }
  const validation = checkService(service)
  if (service.changed && validation !== true) validation.forEach(({ field, message }) => { errors[field] = message })

  const canSave = service.saving || !service.changed || errors.name !== null || errors.url !== null

  const validService = service.valid.http &&
  service.valid.capabilities &&
  (service.valid.read === true || service.valid.read.length === 0) &&
  (service.valid.write === true || service.valid.write.length === 0)

  return (
    <div className='service'>
      <Title>{!validService ? <WanringIcon /> : null} {service.title}</Title>
      <div>
        <Panel label='Information' icon={<CogIcon />}>
          <ServiceName service={service} setService={setService} error={errors.name} />
          <ServiceNetworkId service={service} setService={setService} error={errors.networkId} disabled />
          <ServiceUrl service={service} setService={setService} error={errors.url} />
          <ServiceToken service={service} setService={setService} />
          <UpdateButton service={service} setService={setService} save={save} canSave={canSave} />
        </Panel>
        <Panel label='Status' icon={validService ? <ValidListIcon /> : <InvalidListIcon />}>
          <Status valid={service.valid.http} title='HTTP' message='Unable to connect' />
          <Status valid={service.valid.read === true || service.valid.read.length === 0} title='Read' message='Unable to read data from service' endpoints={service.valid.read} />
          <Status valid={service.valid.write === true || service.valid.write.length === 0} title='Write' message='Unable to write data to service' endpoints={service.valid.write} />
          <Status valid={service.valid.remove === true || service.valid.remove.length === 0} title='Remove' message='Unable to remove data from service' endpoints={service.valid.remove} />
        </Panel>
        <Panel label='Users' icon={<ProfileIcon />}>
          {users.users.map(({ name, role }) => <User key={`${name}-${role}`} name={name} role={role} removeUser={removeUser} service={service.name} changeRole={changeRole} />)}
          {users.requests.map((name) => <Request service={service.name} key={name} name={name} approve={approve} decline={decline} />)}
        </Panel>
        <Invite invite={invite} service={service.name} />
        <Options service={service} removeService={removeService} leave={leave} reload={reload} />
      </div>
    </div>
  )
}

export default AdminService
