import React from 'react'
import merge from 'merge'

import InputText from './input-text'

const ServiceName = ({ service, setService, error }) => (
  <InputText
    name={`${service} service name`}
    disabled={service.saving}
    danger={error}
    message={error}
    label='Name'
    value={service.name || ''}
    onChange={(value) => {
      setService(merge(true, service, { name: value, changed: true, saved: false }))
    }}
  />
)

export default ServiceName
