import React, { useState, useEffect } from 'react'
import { recursive } from 'merge'

import Head from '../components/head'
import Loading from '../components/loading'
import Title from '../components/title'
import Panel from '../components/panel'
import TransactionSidebar from '../components/transactions-sidebar'

const PAGE_SIZE = 10

const Transaction = ({ user, admin, time, id, type, status, messages }) => (
  <div className='columns'>
    <div className='column is-3'>
      <p className='transaction-date'>{new Date(time).toLocaleString()}</p>
      {admin ? <p className='transaction-user'>{user}</p> : null}
    </div>
    <div className='column is-9'>
      <Loading passed={status === 'done'} failed={status === 'error'}>
        <span className='transaction-type'>{type}</span>
        <small className='transaction-id'>{id}</small>
      </Loading>
      <Panel label={messages[0]}>
        <ul className='transaction-messages'>
          {messages.map((message, index) => <li key={`message-${index}`} className='transaction-message'>{message}</li>)}
        </ul>
      </Panel>
    </div>
  </div>
)

const ServiceTranactions = ({ admin, name, transactions, more, getMore, size, total }) => {
  const [floor, setFloor] = useState(false)
  return (
    <div>
      <Title>{name} <small>Showing {transactions.length}/{total}</small></Title>
      <div
        className='service-transactions' onScroll={(evt) => {
          const element = evt.target
          const reachedFloor = element.scrollHeight - 25 <= element.scrollTop + element.clientHeight
          if (more && !floor && reachedFloor) {
            setFloor(true)
            getMore()
          } else setFloor(false)
        }}
      >
        {transactions.length === 0
          ? <div><p className='content has-text-centered'>There are no transactions available</p></div>
          : transactions.map(transaction => <Transaction key={transaction.time} admin={admin} {...transaction} />)}

        {transactions.length > 0 && more
          ? (
            <div className={`columns service-transactions-loading is-${floor ? 'active' : 'inactive'}`}>
              <div className='column is-7' />
              <div className='column'>
                <Loading>Retrieving more transactions</Loading>
              </div>
            </div>
          )
          : null}
      </div>
    </div>
  )
}

const Transactions = ({ services, getMore }) => (
  <div className='transactions'>
    {services.map(service => <ServiceTranactions key={service.name} {...service} getMore={() => getMore(service.name)} />)}
  </div>
)

export default ({ api, profile }) => {
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState({ services: [] })
  const [services, setServices] = useState([])
  const [filters, setFilters] = useState({
    users: '',
    id: '',
    types: {
      insert: true,
      update: true,
      delete: true
    },
    status: {
      done: true,
      error: true,
      'in-progress': true
    },
    services: {},
    sizes: {}
  })

  useEffect(() => {
    document.title = 'Transactions'

    const onGetUserServices = (services) => {
      setLoading(false)
      setServices(services)
      const filterServices = {}
      services.forEach(({ name }) => { filterServices[name] = true })
      const newFilters = recursive(true, filters, { services: filterServices })
      api.emitDebounced('get-transactions', { filters: newFilters })
      setFilters(newFilters)
    }
    api.on('get-user-services-reply', onGetUserServices)

    const onGetTransactionsReply = ({ transactions }) => {
      setTransactions(transactions)
    }
    api.on('get-transactions-reply', onGetTransactionsReply)

    const onTransactionsUpdated = () => {
      api.emitDebounced('get-transactions', { filters })
    }
    api.on('transactions-updated', onTransactionsUpdated)

    const onTransactionsUpdate = () => {
      api.emit('get-transactions', { filters })
    }
    api.on('transactions-updated', onTransactionsUpdate)

    const onUpdate = () => {
      api.emit('get-user-services')
    }
    api.on('connect', onUpdate)

    if (loading) {
      api.emitUntilResponse('get-user-services', {}, 'get-user-services-reply')
    }

    return () => {
      api.off('get-transactions-reply', onGetTransactionsReply)
      api.off('transactions-updated', onTransactionsUpdate)
      api.off('connect', onUpdate)
      api.off('get-user-services-reply', onGetUserServices)
      api.on('transactions-updated', onTransactionsUpdated)
    }
  })

  const canViewUsers = transactions.services.some(({ admin }) => admin)

  return (
    <div>
      <Head>Transactions</Head>
      {profile.loggedIn
        ? (
          <div className='columns'>
            <TransactionSidebar
              services={services}
              canViewUsers={canViewUsers}
              filters={filters}
              onChange={(filter) => {
                const newFilters = recursive(true, filters, filter)
                setFilters(newFilters)
                api.emit('get-transactions', { filters: newFilters })
              }}
            />
            <div className='column is-9' data-tour='transactions'>
              {transactions.loading
                ? <Loading>Searching for transactions ...</Loading>
                : (
                  <Transactions
                    services={transactions.services}
                    getMore={(service) => {
                      const sizes = filters.sizes
                      sizes[service] = sizes[service] || 0
                      sizes[service] += PAGE_SIZE
                      const newFilters = recursive(true, filters, { sizes })
                      setFilters(newFilters)
                      api.emit('get-transactions', { filters: newFilters })
                    }}
                  />
                )}
            </div>
          </div>
        )
        : <div><p className='content has-text-centered'>You need to be logged in to view transactions</p></div>}
    </div>
  )
}
