import React from 'react'
import InputText from './input-text'
import File from './file'
import Button from './button'
import UploadIcon from './icons/upload-icon'
import SpinnerIcon from './icons/spinner-icon'
import Select from './select'
import FoiForm from './foi-form'

const lineRegex = /(?:,|\n|^)("(?:(?:"")*[^"]*)*"|[^",\n]*|(?:\n|$))/g

const parseLine = line => {
  const matched = line.match(lineRegex)
  if (matched !== null) return matched.map(m => m.replace(/^,/g, '').replace(/^"/g, '').replace(/"$/g, '').trim())
  return []
}

const validateHead = (input) => {
  const errors = []

  const head = parseLine(input.toLowerCase())

  const sitecodeIndex = head.includes('sitecode')
  const sitecodeAltIndex = head.includes('site_code')

  if (!sitecodeIndex && !sitecodeAltIndex) errors.push('The header does not have SITECODE or SITE_CODE')

  const fieldnameIndex = head.includes('fieldname')
  const fieldnameAltIndex = head.includes('subst')

  if (!fieldnameIndex && !fieldnameAltIndex) errors.push('The header does not have FIELDNAME or SUBST')

  const valueIndex = head.includes('value')
  const valueAltIndex = head.includes('val')

  if (!valueIndex && !valueAltIndex) errors.push('The header does not have VALUE or VAL')

  const dateIndex = head.includes('date')
  const dateAltIndex = head.includes('sdate')

  const dayIndex = head.includes('day')
  const dayAltIndex = head.includes('sday')

  const monthIndex = head.includes('month')
  const monthAltIndex = head.includes('smonth')

  const yearIndex = head.includes('year')
  const yearAltIndex = head.includes('syear')

  const hasDate = dateIndex || dateAltIndex
  const hasDay = dayIndex || dayAltIndex
  const hasMonth = monthIndex || monthAltIndex
  const hasYear = yearIndex || yearAltIndex
  const hasSplitDate = hasDay && hasMonth && hasYear

  if (!hasDate && !hasSplitDate) errors.push(`you need either a DATE or DAY, MONTH, and YEAR, got ${JSON.stringify(head)}`)

  return errors
}

const read = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = function (evt) {
    resolve(evt.target.result)
  }
  reader.readAsText(file)
})

const UploadData = ({
  uploadData,
  isNewKey,
  keys,
  file,
  foi,
  fois,
  onChange,
  onFileChange,
  errors,
  service,
  upload,
  disabled,
  setFileErrors,
  isUploading
}) => (
  <div className='upload-data'>
    <p className='page-title'>File</p>
    <div className='columns'>
      <div className='column is-half'>
        <InputText
          name='rid'
          value={uploadData.ridIdentifier || ''}
          disabled={disabled}
          label='RID CSV header value e.g. RID, AWSNO'
          onChange={(ridIdentifier) => onChange({ ridIdentifier })}
          message={errors.ridIdentifier}
        />
      </div>
      <div className='column is-half'>
        <Select
          name='key'
          value={!isNewKey ? uploadData.key || '' : '_new_key'}
          items={keys.map(key => ({ value: key })).concat([{ value: '_new_key', label: '+ New Key' }])}
          disabled={disabled}
          label='Key'
          onChange={(key) => onChange({ key })}
          message={errors.key}
        />
        {isNewKey
          ? (
            <InputText
              name='new-key'
              value={uploadData.key === '_new_key' ? '' : uploadData.key || ''}
              disabled={disabled}
              label='Key'
              onChange={(key) => onChange({ key })}
              message={errors.key}
            />
          )
          : null}
      </div>
    </div>
    <Select
      name='feature of interest'
      disabled={disabled}
      value={foi.isNew ? '_new_foi' : foi.id || ''}
      label='Feature Of Interest'
      items={fois.map(({ id }) => ({ value: id })).concat([{ value: '_new_foi', label: '+ New Feature of Interest' }])}
      onChange={(id) => {
        const isNew = id === '_new_foi'
        onChange({
          foi: {
            id: isNew ? null : id,
            isNew
          }
        })
      }}
    />
    {foi.isNew ? (
      <FoiForm
        nested
        service={service}
        foi={foi}
        onChange={(foi) => { onChange({ foi: { ...foi, isNew: true } }) }}
        errors={errors.foi || {}}
      />
    ) : null}
    <div className='columns'>
      <div className='column'>
        <File
          disabled={disabled}
          onChange={async (file) => {
            if (file.name) {
              const data = await read(file)
              const rows = data.split('\n')
              const head = rows[0]
              const errors = validateHead(head)
              if (errors.length === 0) onFileChange(file)
              else setFileErrors(errors)
            } else onFileChange(null)
          }}
          message={errors.file}
        />
      </div>
      <div className='column is-3'>
        <Button
          disabled={disabled || file === null || Object.keys(errors).length > 0 || isUploading}
          onClick={upload}
        >
          {isUploading ? <SpinnerIcon /> : <UploadIcon />}
          <span>UPLOAD</span>
        </Button>
      </div>
    </div>
  </div>
)

export default UploadData
