import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='transactions'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M18.1933 8C19.8794 8 20.643 5.91933 19.3481 4.85342L13.9579 0.416325C12.7836 -0.550289 11 0.27391 11 1.78314V6H1C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8H15L18.1933 8ZM1.80667 12C0.120591 12 -0.643021 14.0807 0.651858 15.1466L6.04215 19.5837C7.21641 20.5503 9 19.7261 9 18.2169V14H19C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12H5H1.80667Z' />
    </svg>

  </Icon>
)
