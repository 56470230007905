import React from 'react'
import { Link } from 'react-router-dom'
import Title from './title'
import ArrowIcon from './icons/arrow-icon'
import Button from './button'
import DownloadData from './download-data'
import Loading from './loading'

export default ({
  id,
  service,
  isLoading,
  loadingMessage,
  error,
  downloadData,
  loading,
  onRequestData
}) => (
  <div className='column is-3'>
    <Title>Options</Title>
    {!error && isLoading ? <Loading>{loadingMessage}</Loading> : null}
    <Button className='back-button' to={`/view/sensor?id=${id}&service=${service}`}>
      <ArrowIcon />
      <span>BACK</span>
    </Button>
    <Title>Download</Title>
    <DownloadData
      onRequestData={onRequestData}
      {...downloadData}
      loading={loading}
    />
    {error ? <p className='content is-small has-text-danger'>{error}<br />If this error persists please <Link to='/contact'>send a message</Link></p> : null}
  </div>
)
