import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from './icons/elter-logo'
import LoginIcon from './icons/login-icon'
import LogoutIcon from './icons/logout-icon'
import SearchIcon from './icons/search-icon'
import MapPointIcon from './icons/map-point-icon'
import SensorIcon from './icons/sensor-icon'
import FilesIcon from './icons/files-icon'
import TriangleIcon from './icons/triangle-icon'
import TransactionsIcon from './icons/transactions-icon'
import TimesIcon from './icons/times-icon'
import Modal from './modal'

const UserProfile = ({
  loggedIn,
  logout,
  login,
  name
}) => {
  return (
    <button
      className='navbar-item hero-user' onClick={() => {
        if (loggedIn) logout()
        else login()
      }}
    >
      {loggedIn ? (
        <div>
          <LogoutIcon />
          <span className='hero-user-name'>{name}</span>
        </div>
      ) : (
        <div>
          <LoginIcon />
          <span className='hero-user-name'>Log In</span>
        </div>
      )}
    </button>
  )
}

const NavbarItem = ({
  items,
  label,
  to,
  icon
}) => {
  const [active, setActive] = useState(false)
  if (items) {
    return (
      <div className={`navbar-item has-dropdown is-hoverable is-${active ? 'active' : 'inactive'}`}>
        <button
          className='navbar-link is-arrowless' onKeyDown={(evt) => {
            if (evt.key === 'Enter' || evt.key === ' ') setActive(!active)
          }}
        >
          {label}
          <TriangleIcon />
        </button>
        <div className='navbar-dropdown'>{items.map(item => <NavbarItem key={item.label} {...item} />)}</div>
      </div>
    )
  }
  return (
    <Link to={to} className='navbar-item'>
      {icon}
      {label}
    </Link>
  )
}

export default ({
  login,
  logout,
  profile
}) => {
  const [active, setActive] = useState(false)
  const [logoutActive, setLogoutActive] = useState(false)

  const showLogout = () => { setLogoutActive(true) }
  const dismissLogout = () => { setLogoutActive(false) }

  const items = [{
    label: 'Data',
    icon: <FilesIcon />,
    to: '/insert/data'
  }, {
    label: 'Sensor',
    icon: <SensorIcon />,
    to: '/insert/sensor'
  }, {
    label: 'Feature Of Interest',
    icon: <MapPointIcon />,
    to: '/insert/foi'
  }]

  return (
    <div>
      <Modal
        onDismiss={dismissLogout}
        active={logoutActive}
        title='Log Out?'
        buttons={[{
          onClick: logout,
          label: 'LOG OUT',
          danger: true,
          icon: <LogoutIcon />
        }, {
          onClick: dismissLogout,
          label: 'CANCEL',
          icon: <TimesIcon />
        }]}
      >
        <p className='content'>You are about to log out.</p>
      </Modal>
      <section className='hero'>
        <div className='hero-head'>
          <nav className='navbar'>
            <div className='container'>
              <div className='navbar-brand'>
                <div className='navbar-item'>
                  <Logo />
                </div>
                <span className={`navbar-burger burger ${active ? 'is-active' : ''}`} onClick={() => { setActive(!active) }}>
                  <span />
                  <span />
                  <span />
                </span>
              </div>
              <div className={`navbar-menu ${active ? 'is-active' : ''}`}>
                <div className='navbar-start'>
                  <NavbarItem label='Search' icon={<SearchIcon />} to='/' />
                  <NavbarItem label='Services' to='/services' />
                  <NavbarItem label='Insert' to='/' items={items} />
                  <NavbarItem label='Transactions' to='/transactions' icon={<TransactionsIcon />} />
                </div>
                <div className='navbar-end'>
                  <UserProfile loggedIn={profile.loggedIn} login={login} logout={showLogout} name={profile.nickname} />
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </div>
  )
}
