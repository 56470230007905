import React from 'react'

const NotLoggedInService = () => (
  <div className='service is-register'>
    <p className='content has-text-centered is-disabled'>
    You need to be logged in before you can use services
    </p>
  </div>
)

export default NotLoggedInService
