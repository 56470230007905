import React from 'react'

const Icon = ({ name, children }) => {
  return (
    <span className={`icon icon-${name}`}>
      {children}
    </span>
  )
}

export default Icon
