import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Title from './title'
import Button from './button'
import GraphIcon from './icons/graph-icon'
import UploadIcon from './icons/upload-icon'
import SaveIcon from './icons/save-icon'
import TrashIcon from './icons/trash-icon'
import TimesIcon from './icons/times-icon'
import Loading from './loading'
import Modal from './modal'

export default ({
  sensor,
  service,
  readOnly,
  save,
  canSave,
  canRemove,
  errors,
  remove
}) => {
  const [removeActive, setRemoveActive] = useState(false)
  const [updateActive, setUpdateActive] = useState(false)
  return (
    <div className='sidebar column is-3'>
      <Title>Options</Title>
      {sensor.id === null ? <Loading>Getting Sensor ...</Loading> : null}
      <Button to={`/data/sensor?id=${sensor.id}&service=${service}`} tour='data'>
        <GraphIcon />
        <span>DATA</span>
      </Button>
      <Button
        disabled={readOnly}
        to={`/upload/sensor?id=${sensor.id}&service=${service}`}
        tour='upload'
      >
        <UploadIcon />
        <span>UPLOAD</span>
      </Button>
      <Button
        tour='update'
        disabled={!canSave || readOnly}
        onClick={() => setUpdateActive(true)}
      >
        <SaveIcon />
        <span>UPDATE</span>
      </Button>
      <Button
        tour='remove'
        danger
        disabled={!canRemove || readOnly}
        onClick={() => setRemoveActive(true)}
      >
        <TrashIcon />
        <span>REMOVE</span>
      </Button>
      {!errors.main && readOnly ? <p className='help is-danger'>You require permissions to update or save</p> : null}
      {errors.main ? <p className='content is-small has-text-danger'>{errors.main}<br />If this error persists please <Link to='/contact'>send a message</Link></p> : null}
      <Modal
        onDismiss={() => setRemoveActive(false)}
        active={removeActive}
        title={`Remove ${sensor.id || 'sensor'}?`}
        buttons={[{
          danger: true,
          label: 'YES',
          icon: <TrashIcon />,
          onClick: () => remove()
        }, {
          onClick: () => setRemoveActive(false),
          icon: <TimesIcon />,
          label: 'NO'
        }]}
      >
        <p className='content'>Are you sure you wish to remove {sensor.id || 'sensor'}?</p>
      </Modal>
      <Modal
        onDismiss={() => setUpdateActive(false)}
        active={updateActive}
        title={`Update ${sensor.id || 'sensor'}?`}
        buttons={[{
          danger: true,
          label: 'YES',
          icon: <SaveIcon />,
          onClick: () => save()
        }, {
          onClick: () => setUpdateActive(false),
          icon: <TimesIcon />,
          label: 'NO'
        }]}
      >
        <p className='content'>
        You may want to back up your data before you decide to update {sensor.id || 'sensor'} <br /><Link to={`/data/sensor?id=${sensor.id}&service=${service}`}>Go to Data</Link>
        </p>
      </Modal>
    </div>
  )
}
