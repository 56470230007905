import React, { useState, useEffect } from 'react'
import Head from '../components/head'
import InputText from '../components/input-text'
import Textarea from '../components/textarea'
import Button from '../components/button'
import EmailIcon from '../components/icons/email-icon'
import { checkEmail } from '../validators'

export default ({ api, profile }) => {
  const [email, setEmail] = useState(profile.name)
  const [message, setMessage] = useState('')
  const emailError = checkEmail({ email })
  const hasEmailError = email.length > 0 && emailError !== true

  useEffect(() => {
    document.title = 'Contact'
  })

  return (
    <div className='contact'>
      <Head label='Contact' />
      <p className='message'>Please leave any feedback or issues you have about the Central Data Node or any of the Sensor Services</p>
      <InputText
        name='email'
        value={email}
        label='Email Address'
        placeholder='email@address.com'
        icon={<EmailIcon />}
        onChange={(value) => { setEmail(value) }}
        message={hasEmailError ? 'Invalid email address' : ''}
      />
      <Textarea name='message' label='Message' onChange={value => { setMessage(value) }} value={message} />
      <Button
        disabled={email.length === '' || emailError !== true || message.length === 0}
        onClick={() => {
          api.emit('contact', { email, message })
        }}
      >
        <EmailIcon />
        <span>SUBMIT</span>
      </Button>
    </div>
  )
}
