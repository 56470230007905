import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='spinner'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M18.0952 10C18.0952 14.4709 14.4709 18.0952 10 18.0952C5.52914 18.0952 1.90476 14.4709 1.90476 10C1.90476 9.47401 1.47839 9.04762 0.952381 9.04762C0.426374 9.04762 0 9.47401 0 10C0 15.5229 4.47713 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0C9.47399 0 9.04762 0.426389 9.04762 0.952381C9.04762 1.47837 9.47399 1.90476 10 1.90476C14.4709 1.90476 18.0952 5.52911 18.0952 10Z' />
    </svg>
  </Icon>
)
