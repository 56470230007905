import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='info'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M20 10C20 15.5228 15.5228 20 10 20C4.47717 20 0 15.5228 0 10C0 4.47715 4.47717 0 10 0C15.5228 0 20 4.47715 20 10ZM18.0952 10C18.0952 14.4709 14.4709 18.0952 10 18.0952C5.52917 18.0952 1.90479 14.4709 1.90479 10C1.90479 5.52913 5.52917 1.90476 10 1.90476C14.4709 1.90476 18.0952 5.52913 18.0952 10ZM9.39189 14.6216C9.55405 14.7568 9.75676 14.8243 10 14.8243C10.2432 14.8243 10.4459 14.7568 10.6081 14.6216C10.7703 14.4775 10.8514 14.2748 10.8514 14.0135V8.7973C10.8514 8.53603 10.7703 8.33784 10.6081 8.2027C10.4459 8.06757 10.2432 8 10 8C9.75676 8 9.55405 8.06757 9.39189 8.2027C9.23874 8.33784 9.16216 8.53603 9.16216 8.7973V14.0135C9.16216 14.2748 9.23874 14.4775 9.39189 14.6216ZM9.27027 6.55405C9.45045 6.71622 9.6937 6.7973 10 6.7973C10.3063 6.7973 10.5495 6.71622 10.7297 6.55405C10.9099 6.38289 11 6.16216 11 5.89189C11 5.62162 10.9054 5.40541 10.7162 5.24324C10.536 5.08108 10.2973 5 10 5C9.6937 5 9.45045 5.08108 9.27027 5.24324C9.09009 5.40541 9 5.62162 9 5.89189C9 6.16216 9.09009 6.38289 9.27027 6.55405Z' />
    </svg>
  </Icon>
)
