import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='square-check'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1 0C0.447723 0 0 0.447708 0 1V19C0 19.5523 0.447723 20 1 20H19C19.5523 20 20 19.5523 20 19V1C20 0.447708 19.5523 0 19 0H1ZM2 2H18V18H2V2ZM8.94421 14.8821C9.2832 15.1566 9.74716 15.1731 10.0985 14.9564C10.246 14.8768 10.3755 14.759 10.4707 14.6066L16.1424 5.53004C16.435 5.06169 16.2926 4.44475 15.8242 4.15208C15.3559 3.85942 14.7389 4.00185 14.4463 4.47021L9.34532 12.6334L5.62936 9.6243C5.20013 9.27673 4.57043 9.34293 4.22287 9.77213C3.87531 10.2013 3.9415 10.831 4.3707 11.1786L8.94421 14.8821Z' />
    </svg>
  </Icon>
)
