import React from 'react'
import Icon from './icon'

const Logo = () => (
  <Icon name='logo'>
    <svg width='125' height='40' viewBox='0 0 125 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M50.7021 0L55.0265 2.27027C44.0235 7.40576 38.4744 14.309 34.3893 18.8816C33.1334 20.2874 31.4945 21.2708 29.7035 21.8591C21.872 24.4315 15.7655 26.7722 10.7021 31.027C21.8315 19.9448 28.1996 22.1019 34.0535 15.8771C38.747 10.3608 44.9117 0.867609 50.7021 0Z' fill='#609978' />
      <path d='M29.4056 16.2162C20.0682 16.1951 16.7246 19.2386 11.3516 25.6216C15.0974 17.9225 18.2275 14.1927 33.7299 13.1892L29.4056 16.2162Z' fill='#A4CAD8' />
      <path d='M40.7564 16.4324L38.5943 18.0541C42.1445 26.6308 31.5383 35.7129 12.9726 32.5405L10.0537 34.4865C28.9802 36.7636 44.0118 32.2771 40.7564 16.4324Z' fill='#A5CFDF' />
      <path opacity='0.8' d='M36.1081 9.08109H33.8378C33.2237 17.2009 35.7782 20.3312 43.1351 24.5406V20.8649C37.2574 18.0879 35.3638 15.7139 36.1081 9.08109Z' fill='#B9DDAD' />
      <path opacity='0.8' d='M3.89189 33.8378L0 40L4.97297 37.9459L3.89189 33.8378Z' fill='#80C3DD' />
      <path opacity='0.8' d='M5.72949 30.8108L6.81057 28.1081C9.57725 31.4297 10.3439 33.5048 10.1619 37.6216L7.1349 38.2703C7.20363 34.9805 6.94214 33.3188 5.72949 30.8108Z' fill='#B2D5F1' />
      <path opacity='0.8' d='M8.32422 24.3243L9.94584 21.2973C14.1049 28.6671 16.1358 32.4694 18.2702 37.6216H13.7296C13.2334 32.0156 11.8434 29.1571 8.32422 24.3243Z' fill='#83B7DE' />
      <path opacity='0.8' d='M15.6759 13.6216L12.4326 16.4325C15.2149 27.1259 18.2459 29.6045 26.3786 37.4054L30.4867 37.1892C19.9302 28.498 17.5637 23.2437 15.6759 13.6216Z' fill='#83927F' />
      <path opacity='0.8' d='M22.2703 8.86484L18.9189 10.9189C22.5091 32.5896 29.6273 29.885 36.5406 36.5405L39.4595 34.2702C28.1027 25.9053 24.3144 25.4333 22.2703 8.86484Z' fill='#F7CE5A' />
      <path opacity='0.8' d='M30.9191 8.43243H27.2435C26.1121 23.1969 32.7966 24.7822 41.0813 32.4324L43.1354 29.8378C31.8106 21.9974 29.7066 17.7894 30.9191 8.43243Z' fill='#8FBE75' />
      <path d='M77.3922 31.5745C77.76 31.5745 78.0694 31.6999 78.3202 31.9507C78.571 32.2015 78.6964 32.5108 78.6964 32.8787C78.6964 33.2465 78.571 33.5559 78.3202 33.8067C78.0694 34.0575 77.76 34.1829 77.3922 34.1829H67.0086C66.6408 34.1829 66.3314 34.0575 66.0806 33.8067C65.8465 33.5559 65.7295 33.2465 65.7295 32.8787V15.8988C65.7295 15.5309 65.8549 15.2216 66.1057 14.9708C66.3565 14.72 66.6575 14.5946 67.0086 14.5946C67.3765 14.5946 67.6858 14.72 67.9366 14.9708C68.1874 15.2216 68.3128 15.5309 68.3128 15.8988V31.5745H77.3922Z' fill='#0877BE' />
      <path d='M90.2981 14.5946C90.6492 14.5946 90.9334 14.7116 91.1508 14.9457C91.3849 15.1631 91.5019 15.4473 91.5019 15.7985C91.5019 16.1496 91.3849 16.4422 91.1508 16.6763C90.9334 16.8937 90.6492 17.0024 90.2981 17.0024H84.9056V32.8787C84.9056 33.2465 84.7802 33.5559 84.5294 33.8067C84.2786 34.0575 83.9693 34.1829 83.6014 34.1829C83.2336 34.1829 82.9242 34.0575 82.6734 33.8067C82.4226 33.5559 82.2972 33.2465 82.2972 32.8787V17.0024H76.8797C76.5285 17.0024 76.2359 16.8937 76.0018 16.6763C75.7845 16.4422 75.6758 16.1496 75.6758 15.7985C75.6758 15.4473 75.7845 15.1631 76.0018 14.9457C76.2359 14.7116 76.5285 14.5946 76.8797 14.5946H90.2981Z' fill='#0877BE' />
      <path d='M106.824 31.7751C107.209 31.7751 107.518 31.8838 107.752 32.1012C107.987 32.3185 108.104 32.6028 108.104 32.9539C108.104 33.3218 107.987 33.6228 107.752 33.8569C107.518 34.0742 107.209 34.1829 106.824 34.1829H95.4878C95.12 34.1829 94.8106 34.0575 94.5598 33.8067C94.309 33.5559 94.1836 33.2465 94.1836 32.8787V15.8988C94.1836 15.5142 94.309 15.2049 94.5598 14.9708C94.8106 14.72 95.12 14.5946 95.4878 14.5946H106.824C107.209 14.5946 107.518 14.7116 107.752 14.9457C107.987 15.1631 108.104 15.4557 108.104 15.8235C108.104 16.1747 107.987 16.4589 107.752 16.6763C107.518 16.8937 107.209 17.0024 106.824 17.0024H96.792V23.0971H103.99C104.358 23.0971 104.659 23.2141 104.893 23.4482C105.144 23.6656 105.269 23.9498 105.269 24.301C105.269 24.6521 105.144 24.9447 104.893 25.1788C104.659 25.3962 104.358 25.5048 103.99 25.5048H96.792V31.7751H106.824Z' fill='#0877BE' />
      <path d='M124.413 32.1764C124.581 32.4439 124.664 32.7198 124.664 33.0041C124.664 33.3385 124.547 33.6228 124.313 33.8569C124.096 34.0742 123.795 34.1829 123.41 34.1829C122.925 34.1829 122.549 33.9739 122.281 33.5559L117.968 26.6335H117.516H114.306V32.8787C114.306 33.2465 114.18 33.5559 113.929 33.8067C113.695 34.0575 113.386 34.1829 113.001 34.1829C112.634 34.1829 112.324 34.0575 112.073 33.8067C111.823 33.5559 111.697 33.2465 111.697 32.8787V15.8988C111.697 15.5142 111.823 15.2049 112.073 14.9708C112.324 14.72 112.634 14.5946 113.001 14.5946H117.516C118.753 14.5946 119.857 14.8537 120.827 15.3721C121.813 15.8904 122.574 16.6178 123.109 17.5541C123.661 18.4905 123.937 19.5606 123.937 20.7645C123.937 21.9517 123.636 23.0051 123.034 23.9247C122.432 24.8444 121.613 25.5383 120.576 26.0065L124.413 32.1764ZM117.516 24.2257C118.653 24.2257 119.589 23.908 120.325 23.2726C121.061 22.6205 121.429 21.7845 121.429 20.7645C121.429 19.661 121.061 18.758 120.325 18.0558C119.589 17.3535 118.653 17.0024 117.516 17.0024H114.306V24.2257H117.516Z' fill='#0877BE' />
      <path d='M62.0981 27.1792C62.0981 27.4969 61.9894 27.7645 61.7721 27.9818C61.5547 28.1825 61.2788 28.2828 60.9444 28.2828H51.4136C51.5975 29.4533 52.1242 30.398 52.9937 31.117C53.8799 31.836 54.9584 32.1955 56.2292 32.1955C56.7308 32.1955 57.2491 32.1035 57.7842 31.9196C58.336 31.7356 58.7874 31.5099 59.1386 31.2424C59.3727 31.0585 59.6486 30.9665 59.9662 30.9665C60.2839 30.9665 60.5347 31.0501 60.7187 31.2173C61.0196 31.4681 61.1701 31.7524 61.1701 32.0701C61.1701 32.371 61.0364 32.6218 60.7688 32.8225C60.2003 33.274 59.4897 33.6418 58.6369 33.9261C57.8009 34.2103 56.9983 34.3524 56.2292 34.3524C54.8581 34.3524 53.6291 34.0598 52.5422 33.4746C51.4554 32.8727 50.6026 32.045 49.984 30.9916C49.382 29.9382 49.0811 28.7426 49.0811 27.405C49.0811 26.0673 49.3653 24.8718 49.9338 23.8184C50.519 22.7483 51.3216 21.9206 52.3416 21.3354C53.3783 20.7334 54.5487 20.4324 55.8529 20.4324C57.1404 20.4324 58.2524 20.7167 59.1887 21.2852C60.1251 21.8537 60.8441 22.6479 61.3457 23.6679C61.8473 24.6879 62.0981 25.8583 62.0981 27.1792ZM55.8529 22.5894C54.6323 22.5894 53.6374 22.9238 52.8683 23.5927C52.1159 24.2615 51.6393 25.156 51.4387 26.2763H59.7907C59.6402 25.156 59.2222 24.2615 58.5366 23.5927C57.8511 22.9238 56.9565 22.5894 55.8529 22.5894Z' fill='#EE9634' />
    </svg>
  </Icon>
)

export default Logo
