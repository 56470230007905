import React from 'react'
import SquareIcon from './icons/square-icon'
import SquareCheckIcon from './icons/square-check-icon'

const Checkbox = ({
  disabled = false,
  className = '',
  checked = false,
  icon = null,
  children,
  onChange = () => {},
  message = ''
}) => {
  return (
    <div>
      <button
        disabled={disabled}
        className={`button checkbox is-block ${className}`}
        onClick={() => {
          onChange(!checked)
        }}
      >
        {checked ? <SquareCheckIcon /> : <SquareIcon />}
        {icon}
        <span className='checkbox-label'>{children}</span>
      </button>
      {message ? <p className='content is-small has-text-danger'>{message}</p> : null}
    </div>
  )
}

export default Checkbox
