import React, { useState } from 'react'
import merge from 'merge'

import Button from './button'
import Title from './title'
import SpinnerIcon from './icons/spinner-icon'
import SaveIcon from './icons/save-icon'
import ServiceName from './service-name'
import ServiceUrl from './service-url'
import ServiceToken from './service-token'
import ServiceNetworkId from './service-network-id'
import { checkService } from '../validators'

const Options = ({ service, setService, save, canSave }) => {
  return (
    <div className='columns service-options'>
      <div className='column'>
        {service.saved ? <p className='content has-text-right'>Saved New Service</p> : null}
      </div>
      <div className='column is-2'>
        <Button
          className={service.saving ? 'is-saving' : ''}
          disabled={canSave}
          onClick={async () => {
            setService(merge(true, service, { saving: true }))
            try {
              await save(service)
              setService({ saving: false, changed: false, title: service.name, name: '', url: '', token: '', saved: true })
            } catch (err) {
              setService(merge(true, service, { saving: false }))
            }
          }}
        >
          {service.saving ? <SpinnerIcon /> : <SaveIcon />}
          <span>{!service.saving ? 'SAVE' : 'SAVING'}</span>
        </Button>
      </div>
    </div>
  )
}

const NewService = ({
  original,
  time = 0,
  name,
  networkId,
  url,
  token,
  save,
  valid
}) => {
  const [service, setService] = useState({ original, time, name, networkId, url, token, changed: false, saving: false, title: name, saved: false, valid })
  const errors = { name: null, url: null, networkId: null }
  const validation = checkService(service)
  if (service.changed && validation !== true) validation.forEach(({ field, message }) => { errors[field] = message })

  const canSave = service.saving || !service.changed || errors.name !== null || errors.url !== null

  return (
    <div>
      <Title>Add Existing Service</Title>
      <ServiceName service={service} setService={setService} error={errors.name} />
      <ServiceNetworkId service={service} setService={setService} error={errors.networkId} />
      <ServiceUrl service={service} setService={setService} error={errors.url} />
      <ServiceToken service={service} setService={setService} />
      <Options service={service} setService={setService} save={save} canSave={canSave} />
    </div>
  )
}

export default NewService
