import React from 'react'
import { Link } from 'react-router-dom'
import Title from './title'
import DownloadData from './download-data'
import Loading from './loading'

export default ({
  loadingMessage,
  error,
  downloadData,
  loading,
  onRequestData,
  canDownload
}) => (
  <div className='column is-3'>
    {!error && loading ? <Loading>{loadingMessage}</Loading> : null}
    <Title>Download</Title>
    <DownloadData
      canDownload={canDownload}
      onRequestData={onRequestData}
      {...downloadData}
      loading={loading}
    />
    {error ? <p className='content is-small has-text-danger'>{error}<br />If this error persists please <Link to='/contact'>send a message</Link></p> : null}
  </div>
)
