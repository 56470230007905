import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import qs from 'qs'

import Head from '../components/head'
import Title from '../components/title'
import ViewFoiSidebar from '../components/view-foi-sidebar'
import InputText from '../components/input-text'
import Loading from '../components/loading'

const Body = ({
  id,
  name,
  uri,
  lat,
  lon
}) => (
  <div data-tour='information'>
    <InputText name='id' label='ID' readOnly value={id} />
    <InputText name='name' label='Name' readOnly value={name} />
    <InputText name='uri' label='URI' readOnly value={uri} />
    <div className='columns'>
      <div className='column is-half'>
        <InputText name='latitude' label='Latitude' readOnly value={lat} />
      </div>
      <div className='column is-half'>
        <InputText name='longitude' label='Longitude' readOnly value={lon} />
      </div>
    </div>
  </div>
)

const Sensors = ({ service, sensors }) => (
  <ul data-tour='sensors'>
    {sensors.map(({ id }, index) => (
      <li key={`${id}-${index}`}>
        <Link to={`/view/sensor?id=${id}&service=${service}`}>{id}</Link>
      </li>
    ))}
  </ul>
)

export default ({ api, history }) => {
  const search = qs.parse(history.location.search, { ignoreQueryPrefix: true })
  const id = search.id
  const service = search.service
  const [foi, setFoi] = useState({ id, name: '', uri: '', lat: '', lon: '', loading: true })
  const [error, setError] = useState(null)
  const [sensors, setSensors] = useState([])
  const [downloadData, setDownloadData] = useState({ requested: true, link: '', created: new Date(), expires: new Date() })

  useEffect(() => {
    document.title = 'View Feature of Interest'

    const onGetFoiReply = (foi) => {
      if (foi.error) {
        setError(foi.error)
        setFoi({ loading: false })
      } else setFoi(foi)
      api.emit('get-data-file', { service, id })
      api.emit('get-sensors', { service, foi: id })
    }
    api.on('get-foi-reply', onGetFoiReply)

    const onUpdate = () => { api.emit('get-foi', { service, id }) }

    api.on('connect', onUpdate)
    api.on('user-updated', onUpdate)
    api.on('services-updated', onUpdate)
    api.on('foi-updated', onUpdate)
    api.on('sensor-updated', onUpdate)

    const onGetSensorsReply = ({ sensors }) => {
      setSensors(sensors)
    }
    api.on('get-sensors-reply', onGetSensorsReply)

    const getDataFileReply = ({ exists, filename, created, expires, requested }) => {
      if (exists) {
        setDownloadData({
          requested,
          link: `${process.env.REACT_APP_API_HOST}/${filename}`,
          created: new Date(created),
          expires: new Date(expires)
        })
      } else {
        setDownloadData({
          requested: false,
          link: '',
          created: new Date(),
          expires: new Date(expires)
        })
      }
    }
    api.on('get-data-file-reply', getDataFileReply)

    if (foi.loading) api.emitUntilResponse('get-foi', { service, id }, 'get-foi-reply')

    return () => {
      api.off('get-foi-reply', onGetFoiReply)
      api.off('connect', onUpdate)
      api.off('user-updated', onUpdate)
      api.off('services-updated', onUpdate)
      api.off('foi-updated', onUpdate)
      api.off('sensor-updated', onUpdate)
      api.off('get-sensors', onGetSensorsReply)
      api.off('get-sensors-reply', onGetSensorsReply)
      api.off('get-data-file-reply', getDataFileReply)
    }
  })

  return (
    <div>
      <Head>{id}</Head>
      <div className='columns'>
        <ViewFoiSidebar
          canDownload={sensors.length > 0}
          loadingMessage='Getting sensor'
          downloadData={downloadData}
          loading={foi.loading}
          onRequestData={() => {
            api.emit('request-data', { service, foi: id })
          }}
        />
        <div className='column'>
          {error ? (
            <div>
              <p className='content has-text-centered'>{error}<br />If this problem persists <Link to='/contact'>please get in touch</Link></p>
            </div>
          ) : foi.loading
            ? <Loading>Getting Feature of Interest {id} ...</Loading>
            : <Body {...foi} />}
          <Title>Sensors</Title>
          {!foi.loading && sensors.length > 0
            ? <Sensors sensors={sensors} service={service} />
            : <p className='content has-text-centered'>There are no sensors for this Feature of Interest</p>}
        </div>
      </div>
    </div>
  )
}
