import React from 'react'

import Title from './title'
import Button from './button'
import UploadIcon from './icons/upload-icon'
import TimesIcon from './icons/times-icon'

export default ({
  name,
  registered,
  register,
  leave
}) => (
  <div className='service is-register'>
    <Title>{name}</Title>
    <p className='content has-text-centered is-disabled'>
      {!registered ? 'You need to register before you can make changes to this service' : 'Awaiting approval from an Admin'}
    </p>
    <div className='columns'>
      <div className='column is-10' />
      <div className='column is-2'>
        {!registered ? (
          <Button className='is-request' onClick={() => register(name, 'editor')}>
            <UploadIcon />
            <span>REGISTER</span>
          </Button>
        ) : (
          <Button danger className='is-leave' onClick={() => leave(name)}>
            <TimesIcon />
            <span>LEAVE</span>
          </Button>
        )}
      </div>
    </div>
  </div>
)
