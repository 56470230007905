import React from 'react'

import Title from './title'
import Subtitle from './subtitle'
import InputText from './input-text'
import Textarea from './textarea'
import Select from './select'
import FoiForm from './foi-form'

const show = (readOnly, sensor, key) => {
  if (!readOnly) return true
  return key in sensor
}

const id = (sensor) => sensor.fieldname ? `/${sensor.version}/${sensor.networkId}/${sensor.sitecode}/${sensor.fieldname}${sensor.key ? `/${sensor.key}` : ''}${sensor.rid && sensor.key !== sensor.rid ? `/${sensor.rid}` : ''}` : ''

const Id = ({ sensor }) => (
  <InputText
    name='sensor id'
    disabled
    value={id(sensor)}
    label='ID "/{Network ID}/{Version}/{Feature of Interest}/{Fieldname}/{Key}/{RID}"'
  />
)

const Offering = ({ sensor }) => (
  <InputText
    name='offering'
    disabled
    value={`${id(sensor)}/raw`}
    label='Offering'
  />
)

const NetworkId = ({ sensor }) => (
  <InputText
    name='network id'
    disabled
    value={sensor.networkId}
    label='Network ID'
  />
)

const Version = ({ sensor }) => (
  <InputText
    name='version'
    disabled
    value={sensor.version || 1}
    label='Version'
  />
)

const Foi = ({ readOnly, sensor, service, fois, onChange, errors }) => (
  <div className='sensor-form-foi'>
    {readOnly
      ? (
        <InputText
          name='feature of interest'
          readOnly={readOnly}
          value={(sensor.foi && sensor.foi.id) || ''}
          label='Feature Of Interest'
          disabled={service === null}
        />
      )
      : (
        <Select
          name='feature of interest'
          readOnly={readOnly}
          value={sensor.foi.isNew ? '_new_foi' : sensor.foi.id || ''}
          label='Feature Of Interest'
          disabled={service === null}
          items={fois.map(({ id }) => ({ value: id })).concat([{ value: '_new_foi', label: '+ New Feature of Interest' }])}
          onChange={(id) => {
            const isNew = id === '_new_foi'
            onChange({
              sitecode: id,
              foi: {
                id: isNew ? null : id,
                isNew
              }
            })
          }}
        />
      )}
    {sensor.foi.isNew
      ? (
        <FoiForm
          nested
          service={service}
          foi={sensor.foi}
          onChange={(foi) => {
            onChange({ foi: { ...foi, isNew: true }, sitecode: foi.id })
          }}
          errors={errors.foi || {}}
        />
      )
      : null}
  </div>
)

const Fieldname = ({ readOnly, sensor, errors, service, onChange }) => show(readOnly, sensor, 'fieldname') ? (
  <InputText
    name='fieldname'
    readOnly={readOnly}
    message={errors.fieldname}
    value={sensor.fieldname || ''}
    label='Fieldname'
    disabled={service === null}
    onChange={(fieldname) => { onChange({ fieldname }) }}
  />
) : null

const Key = ({ readOnly, sensor, errors, service, onChange }) => show(readOnly, sensor, 'key') ? (
  <InputText
    name='key'
    readOnly={readOnly}
    message={errors.key}
    value={sensor.key || ''}
    label='KEY'
    disabled={service === null}
    onChange={(key) => { onChange({ key }) }}
  />
) : null

const Rid = ({ readOnly, sensor, errors, service, onChange }) => show(readOnly, sensor, 'rid') ? (
  <InputText
    name='rid'
    readOnly={readOnly}
    message={errors.rid}
    value={sensor.rid || ''}
    label='RID'
    disabled={service === null}
    onChange={(rid) => { onChange({ rid }) }}
  />
) : null

export default ({
  service,
  sensor,
  fois,
  onChange,
  errors,
  readOnly,
  isUpdate
}) => {
  return (
    <div className='column insert-form' data-tour='information'>
      <Title>Sensor Information</Title>
      <Subtitle>Required Fields</Subtitle>
      <p className='content'>The following fields are required for a sensor{isUpdate ? <span><br />When updating sensors with large datasets this could take a long time</span> : null}</p>
      <Id sensor={sensor} />
      <Offering sensor={sensor} />
      <NetworkId sensor={sensor} />
      <Version sensor={sensor} />
      <Fieldname readOnly={readOnly} sensor={sensor} service={service} onChange={onChange} errors={errors} />
      <Foi readOnly={readOnly} sensor={sensor} service={service} fois={fois} onChange={onChange} errors={errors} />
      <Key readOnly={readOnly} sensor={sensor} service={service} onChange={onChange} errors={errors} />
      <Rid readOnly={readOnly} sensor={sensor} service={service} onChange={onChange} errors={errors} />

      <InputText
        name='observed preference label'
        readOnly={readOnly}
        message={errors.obs_pref_label}
        value={sensor.obs_pref_label || ''}
        label='Observed Preference Label'
        disabled={service === null}
        onChange={(obsPrefLabel) => { onChange({ obs_pref_label: obsPrefLabel }) }}
      />
      <div className='columns'>
        <div className='column is-4'>
          <InputText
            name='output'
            readOnly={readOnly}
            message={errors.output_name}
            value={sensor.output_name || ''}
            label='Output Name'
            disabled={service === null}
            onChange={(outputName) => { onChange({ output_name: outputName }) }}
          />
        </div>
        <div className='column is-6'>
          <InputText
            name='observerable property'
            readOnly={readOnly}
            message={errors.output_op_definition}
            value={sensor.output_op_definition || ''}
            label='Output Observable Property Definition'
            disabled={service === null}
            onChange={(outputOpDefinition) => { onChange({ output_op_definition: outputOpDefinition }) }}
          />
        </div>
        <div className='column is-2'>
          <InputText
            name='units'
            readOnly={readOnly}
            message={errors.units}
            value={sensor.units || ''}
            label='Output Units'
            disabled={service === null}
            onChange={(units) => { onChange({ units }) }}
          />
        </div>
      </div>

      <Subtitle>Optional Fields</Subtitle>
      {show(readOnly, sensor, 'name') ? (
        <InputText
          name='name'
          readOnly={readOnly}
          message={errors.name}
          value={sensor.name || ''}
          label='Sensor Name'
          disabled={service === null}
          onChange={(name) => { onChange({ name }) }}
        />
      ) : null}
      {show(readOnly, sensor, 'desc') ? (
        <Textarea
          name='descripton'
          readOnly={readOnly}
          label='Description'
          message={errors.desc}
          value={sensor.desc || ''}
          disabled={service === null}
          onChange={(desc) => { onChange({ desc }) }}
        />
      ) : null}
      {show(readOnly, sensor, 'make') && show(readOnly, sensor, 'model') ? (
        <div className='columns'>
          <div className='column is-half'>
            <InputText
              name='make'
              readOnly={readOnly}
              message={errors.make}
              value={sensor.make || ''}
              label='Make'
              disabled={service === null}
              onChange={(make) => { onChange({ make }) }}
            />
          </div>
          <div className='column is-half'>
            <InputText
              name='model'
              readOnly={readOnly}
              message={errors.model}
              value={sensor.model || ''}
              label='Model'
              disabled={service === null}
              onChange={(model) => { onChange({ model }) }}
            />
          </div>
        </div>
      ) : null}

      {show(readOnly, sensor, 'alt') ? (
        <InputText
          name='height'
          readOnly={readOnly}
          message={errors.alt}
          value={sensor.alt || ''}
          label='Height'
          disabled={service === null}
          onChange={(alt) => { onChange({ alt }) }}
        />
      ) : null}
    </div>
  )
}
