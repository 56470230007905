import React, { useState } from 'react'

import Button from './button'
import HelpIcon from './icons/help-icon'
import Modal from './modal'
import Markdown from './markdown'
import DownloadFileIcon from './icons/download-icon'

export default () => {
  const [dataFormatActive, setDataFormatActive] = useState(false)
  return (
    <div className='data-format'>
      <Button onClick={() => {
        setDataFormatActive(!dataFormatActive)
      }}
      >
        <HelpIcon />
        <span>DATA FORMAT</span>
      </Button>
      <Modal
        title='CSV Data Format'
        active={dataFormatActive}
        onDismiss={() => {
          setDataFormatActive(false)
        }}
      >
        <Markdown>{`The data you upload should be a CSV file with the following allowable fields

Field | Alias
---|---
SITECODE | SITE_CODE
FIELDNAME | SUBST
VALUE | VAL
HOUR | SHOUR
DATE | SDATE
DAY | SDAY
MONTH | SMONTH
YEAR | SYEAR
MINUTE | SMINUTE
SECOND | SSECOND

The data may also have an optional RID Identifier e.g. AWSNO

Either use **DATE** or **DAY**, **MONTH**, **YEAR** and not both

**DATE** can be one of the following formats \`yyyymmddHHMMSS\` \`yyyymmdd\` \`yyyy-mm-dd HH:MM:SS\` and ISO format

**HOUR**, **MINUTE** and **SECOND** are optional fields

All other fields will be ignored

Here are some example files, you may want to use them as templates for your data
`}
        </Markdown>
        <ul>
          <li>
            <a href='/samples/with-date-iso.csv' download>
              <DownloadFileIcon />
              <span>With DATE (ISO Format)</span>
            </a>
          </li>
          <li>
            <a href='/samples/with-date-yyyymmdd.csv' download>
              <DownloadFileIcon />
              <span>With DATE (yyyymmdd Format)</span>
            </a>
          </li>
          <li>
            <a href='/samples/with-date-day-month-year.csv' download>
              <DownloadFileIcon />
              <span>With DAY, MONTH, YEAR</span>
            </a>
          </li>
          <li>
            <a href='/samples/with-date-hour-minute-second.csv' download>
              <DownloadFileIcon />
              <span>With HOUR, MINUTE, SECOND</span>
            </a>
          </li>
          <li>
            <a href='/samples/with-fieldname.csv' download>
              <DownloadFileIcon />
              <span>With FIELDNAME</span>
            </a>
          </li>
          <li>
            <a href='/samples/with-subst.csv' download>
              <DownloadFileIcon />
              <span>With SUBST</span>
            </a>
          </li>
          <li>
            <a href='/samples/with-rid-awsno.csv' download>
              <DownloadFileIcon />
              <span>With RID Identifier of AWSNO</span>
            </a>
          </li>
          <li>
            <a href='/samples/with-no-rid.csv' download>
              <DownloadFileIcon />
              <span>Without RID Identifier</span>
            </a>
          </li>
        </ul>
      </Modal>
    </div>
  )
}
