import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='refresh'>
    <svg width='18' height='20' viewBox='0 0 18 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M8.49637 0.0664058C8.17184 -0.149889 7.7999 0.188582 7.80005 0.700057L7.80061 2.58152C3.53559 3.16616 0.25 6.82437 0.25 11.25C0.25 16.0825 4.1675 20 9 20C13.8325 20 17.75 16.0825 17.75 11.25C17.75 10.7897 17.3769 10.4167 16.9167 10.4167C16.4564 10.4167 16.0833 10.7897 16.0833 11.25C16.0833 15.162 12.912 18.3333 9 18.3333C5.088 18.3333 1.91667 15.162 1.91667 11.25C1.91667 7.74663 4.46007 4.83728 7.80112 4.2677L7.80173 6.30042C7.80189 6.82796 8.19557 7.16509 8.52147 6.91676L12.441 3.92988C12.7969 3.6587 12.7832 2.92354 12.4176 2.67991L8.49637 0.0664058Z' />
    </svg>
  </Icon>
)
