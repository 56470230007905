import React, { useState, useEffect } from 'react'
// import Range from '../components/range'
import qs from 'qs'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import Title from '../components/title'
import Head from '../components/head'
import DataSidebar from '../components/data-sensor-sidebar'
import Loading from '../components/loading'

export default ({ history, api, profile }) => {
  const search = qs.parse(history.location.search, { ignoreQueryPrefix: true })
  const id = search.id
  const service = search.service

  const [data, setData] = useState({ values: [], start: null, end: null, loading: true })
  const [total, setTotal] = useState(null)
  const [error, setError] = useState(null)
  const [downloadData, setDownloadData] = useState({ requested: true, link: '', created: new Date(), expires: new Date() })

  useEffect(() => {
    document.title = 'Sensor Data'

    if (data.loading) {
      api.emitUntilResponse('get-data', { service, id }, 'get-data-reply', 15000)
    }

    const onGetDataReply = ({ data, error }) => {
      if (error) setError(error)
      else {
        setData(data)
        api.emit('get-data-file', { service, id })
        api.emitDebounced('get-data-total', { service, id })
      }
    }
    api.on('get-data-reply', onGetDataReply)

    const getDataFileReply = ({ exists, filename, created, expires, requested }) => {
      if (exists) {
        setDownloadData({
          requested,
          link: `${process.env.REACT_APP_API_HOST}/${filename}`,
          created: new Date(created),
          expires: new Date(expires),
          canDownload: true
        })
      } else {
        setDownloadData({
          requested: false,
          link: '',
          created: new Date(),
          expires: new Date(expires),
          canDownload: true
        })
      }
    }
    api.on('get-data-file-reply', getDataFileReply)

    const onGetDataTotalReply = ({ total, error }) => {
      if (error) setError(error)
      else setTotal(total)
    }
    api.on('get-data-total-reply', onGetDataTotalReply)

    const onUpdate = () => { api.emit('get-data', { service, id }) }
    api.on('connect', onUpdate)
    api.on('data-updated', onUpdate)
    api.on('services-updated', onUpdate)

    return () => {
      api.off('get-data-reply', onGetDataReply)
      api.off('get-data-total-reply', onGetDataTotalReply)
      api.off('connect', onUpdate)
      api.off('data-updated', onUpdate)
      api.off('services-updated', onUpdate)
      api.off('get-data-file-reply', getDataFileReply)
    }
  })

  let values = data.values
  if (values.length > 1000) values = values.slice(values.length - 1000, values.length)

  return (
    <div>
      <Head>{id}</Head>
      <div className='columns'>
        <DataSidebar
          id={id}
          service={service}
          isLoading={data.start === null}
          loadingMessage='Getting Data ...'
          error={error}

          downloadData={downloadData}
          loading={data.loading}
          onRequestData={() => {
            api.emit('request-data', { service, sensor: id })
          }}
        />
        <div className='column'>
          <div className='columns'>
            <div className='column is-one-third' data-tour='total'>
              <Title>Total Values</Title>
              {total === null ? <Loading>Getting Total</Loading> : <p className='content is-large'>{total}</p>}
            </div>
            <div className='column is-one-third' data-tour='start-time'>
              <Title>Start Time</Title>
              <p className='content is-large'>{data.start}</p>
            </div>
            <div className='column is-one-third' data-tour='end-time'>
              <Title>End Time</Title>
              <p className='content is-large'>{data.end}</p>
            </div>
          </div>
          <div data-tour='graph'>
            <Title>Last {values.length} Values</Title>
            {/* <Range onChange={([from, to]) => {
          console.log(from, to)
        }} /> */}
            <div style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <LineChart data={values}>
                  <CartesianGrid strokeDasharray='3 3' stroke='#093754' />
                  <XAxis dataKey='time' stroke='#093754' />
                  <YAxis stroke='#093754' />
                  <Tooltip />
                  <Line dataKey='value' stroke='#3273dc' dot={false} strokeWidth='2' />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
