import React, { useEffect } from 'react'

import Head from '../components/head'
import Panel from '../components/panel'
import Markdown from '../components/markdown'

export default ({ history, api, profile }) => {
  useEffect(() => {
    document.title = 'Help'
  })

  return (
    <div>
      <Head>Help</Head>
      <p className='content has-text-centred'>
        Some Q/A to help you use the Central Data Node
      </p>
      <Panel label='How can I find a Sensor and its data?'>
        <Markdown>{`You can [Search](link=/) for a Sensor by ID and by service.

Once you have found your ID select the "DATA" button in order to see the data for that Sensor.

There you will also be able to download all the data for that Sensor.`}
        </Markdown>
      </Panel>
      <Panel label='How can I find a Feature of Interest?'>
        <Markdown>{`You can [Search](link=/) for a Feature of Interest by ID and by service.

You can see all Sensors that share this Feature of Interest and download all the associated data from this page.`}
        </Markdown>
      </Panel>
      <Panel label='How do I upload a CSV of data for many Sensors?'>
        <Markdown>{`From the menu go to Insert then select Data or follow this [Link](link=/inser/data)

Next select which Service you wish to add the data to.

Once selected all the metadata for field names will be shown. You can update these or add other field names to the list.

You can specify your RID Fieldname, which is the header in the CSV file and you can also provide a key for all the Sensors.

Then select or a Feature of Interest from the drop down. You can choose to create a Feature of Interest if it does not exist from the dropdown. When you upload the file it will try to deduce the Feature of Interest for you, so if some sensor already exists that is in your dataset you do not need to select a Feature of Interest.

Then upload the file. On the confirmation screen review all the Sensors that will be inserted, any red warnings will need to be addressed if you want to insert that data. In the screen, you can choose not to add data for certain Sensors. Once you are happy click "CONFIRM" to upload the data.

You will be taken to the Transactions page where you will see the progress of this. You do not need to keep your browser open for this change to happen.`}
        </Markdown>
      </Panel>
      <Panel label='How do I upload data for a single Sensor?'>
        <Markdown>{`Using [Search](link=/) find the Sensor you wish to add data for and click it.

From this page, you can click "UPLOAD" and from here you will be able to insert a file.

Once uploaded it will take you to the Transactions page where you can view the progress. You do not need to keep your browser open for this change to happen.`}
        </Markdown>
      </Panel>
      <Panel label='How do I modify a Sensor?'>
        <Markdown>{`Using [Search](link=/) find the Sensor you wish to add data for and click it.

If you have permissions you will be able to change the fields on the page, then click "UPDATE".

Once update it will take you to the Transactions page where you can view the progress. You do not need to keep your browser open for this change to happen.

It is advised that if you change any of the "Required" fields that you download the data before you do any changes.`}
        </Markdown>
      </Panel>
      <Panel label='How do I add/create a new Service?'>
        <Markdown>{`Go to [Services](link=/services) and go to the bottom of the page.

You can then add a Service by providing its name (can be anything), URL and network ID. If your service has an authorization token you can provide that as well.

Once you save this you will immediately be made an admin where you can invite people by email address or manage current users with access to your service.

If you do not have a Service but wish to have one made we can provide that for you. Simply send a message using our [Contact](/contact) page.`}
        </Markdown>
      </Panel>
      <Panel label='How do I edit a Service?'>
        <Markdown>{`If you are an admin of service you can edit any of the information about it, including name, URL, network ID and authorization token.

If you wish to change anything about the service and we created it for you, for example, if you wish to update its contact information, install features to it or enable APIs please send a message using our [Contact](/contact) page.`}
        </Markdown>
      </Panel>
      <Panel label='I found an issue with the app or wish to request a new feature, how can I report it?'>
        <Markdown>Simply send us a message using our [Contact](/contact) page.
        </Markdown>
      </Panel>
      <Panel label='My Sensor(s) have gone from the app, how do I get it and all the data back?'>
        <Markdown>{`If your service is provided by us we create a backup every 24 hours so you may be able to reclaim that data if it was inserted the day before.
      
Unfortunately, we do not backup the data any more regularly than that

If the data is not saved on a service we created we will not be able to recover that data for you.

It is suggested that before you do anything, such as removing a sensor or updating a "Required" field, download the data before starting.`}
        </Markdown>
      </Panel>
    </div>
  )
}
