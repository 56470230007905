import React, { useState } from 'react'

import Button from './button'

export default ({ items = [], value, onChange = () => {} }) => {
  const [selected, setSelected] = useState(value)
  return (
    <div className='buttons has-addons'>{
      items.map(item => (
        <Button
          onClick={() => {
            setSelected(item.value)
            onChange(item.value)
          }}
          className={selected === item.value ? 'is-selected' : 'is-unselected'}
          key={item.value}
          fullwidth={false}
          style={{
            width: `${100 / items.length}%`
          }}
        >
          {item.icon}
          {item.label}
        </Button>
      ))
    }
    </div>
  )
}
