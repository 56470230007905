import React from 'react'
import merge from 'merge'

import InputText from './input-text'

const ServiceUrl = ({ service, setService, error }) => (
  <InputText
    name={`${service} service url`}
    disabled={service.saving}
    danger={error}
    message={error}
    label='URL'
    value={service.url || ''}
    onChange={(value) => {
      setService(merge(true, service, { url: value, changed: true, saved: false }))
    }}
  />
)

export default ServiceUrl
