import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='save'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M5.5 1C5.5 0.447716 5.94775 0 6.5 0H13.5C14.0522 0 14.5 0.447716 14.5 1V2H16.7032H19C19.5522 2 20 2.44772 20 3C20 3.55228 19.5522 4 19 4H17.574L15.4714 19.1376C15.4028 19.632 14.9801 20 14.481 20H5.22241C4.72327 20 4.30054 19.632 4.23193 19.1376L2.12939 4H1C0.447754 4 0 3.55228 0 3C0 2.44772 0.447754 2 1 2H3.00012H5.5V1ZM4.14868 4L6.09314 18H13.6102L15.5547 4H13.5H6.5H4.14868ZM7.85168 6C7.29944 6 6.85168 6.44772 6.85168 7V13C6.85168 13.5523 7.29944 14 7.85168 14C8.40393 14 8.85168 13.5523 8.85168 13V7C8.85168 6.44772 8.40393 6 7.85168 6ZM10.8517 7C10.8517 6.44772 11.2994 6 11.8517 6C12.4039 6 12.8517 6.44772 12.8517 7V13C12.8517 13.5523 12.4039 14 11.8517 14C11.2994 14 10.8517 13.5523 10.8517 13V7Z' />
    </svg>
  </Icon>
)
