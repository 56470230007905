import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Head from '../components/head'

const NotFoundPage = () => {
  useEffect(() => {
    document.title = '404 Not Found'
  })
  return (
    <div>
      <Head>Not Found</Head>
      <p className='content has-text-centered'>
        <span>The page you are looking for does not exist</span>
        <br />
        <span>If you have any issues please <Link to='/contact'>Contact Us</Link></span>
      </p>

    </div>
  )
}

export default NotFoundPage
