import React from 'react'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import Head from './head'
import Title from './title'

const MarkdownLink = ({ href, children }) => {
  if (href.startsWith('link=')) return <Link to={href.replace('link=', '')}>{children}</Link>
  return <a href={href}>{children}</a>
}

const MarkdownHeading = ({ level, children }) => {
  if (level === 2) return <Title>{children}</Title>
  return <Head>{children}</Head>
}

export default ({ children, className = '' }) => (
  <ReactMarkdown
    className={`markdown ${className}`}
    renderers={{
      link: MarkdownLink,
      heading: MarkdownHeading,
      table: (props) => {
        return (
          <table className='table is-fullwidth'>
            {props.children}
          </table>
        )
      }
    }}
  >{children}
  </ReactMarkdown>
)
