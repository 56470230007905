import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='file'>
    <svg width='17' height='20' viewBox='0 0 17 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M16.575 6.5L16.7619 6.71993C16.9156 6.90073 17 7.13029 17 7.36758V10V11V18V19C17 19.5523 16.5523 20 16 20H15.3H1.7H1C0.447715 20 0 19.5523 0 19V18V2V1C0 0.447715 0.447715 0 1 0H1.7H7.65H8.5H10.5876C10.8809 0 11.1595 0.128823 11.3495 0.352352L16.575 6.5ZM14.45 7L10.2 2V7H14.45ZM8.5 7V2H1.7V18H15.3V9H10.2H9.5C8.94771 9 8.5 8.55229 8.5 8V7Z' />
    </svg>
  </Icon>
)
