import React from 'react'

import DataFormat from './data-format'
import ServicesSidebar from './services-sidebar'

export default ({
  services,
  service,
  setService,
  errors,
  children
}) => {
  return (
    <ServicesSidebar
      services={services}
      service={service}
      setService={setService}
      errors={errors}
    >
      <DataFormat />
    </ServicesSidebar>
  )
}
