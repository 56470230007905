import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='help'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M20 10C20 15.5228 15.5228 20 10 20C4.47717 20 0 15.5228 0 10C0 4.47715 4.47717 0 10 0C15.5228 0 20 4.47715 20 10ZM18.0952 10C18.0952 14.4709 14.4709 18.0952 10 18.0952C5.52917 18.0952 1.90479 14.4709 1.90479 10C1.90479 5.52912 5.52917 1.90476 10 1.90476C14.4709 1.90476 18.0952 5.52912 18.0952 10ZM9.52185 12.6181C9.6405 12.7476 9.79163 12.8124 9.9751 12.8124C10.1478 12.8124 10.2882 12.7692 10.3961 12.6829C10.5148 12.5857 10.5903 12.4508 10.6228 12.2781C10.6768 11.8895 10.8116 11.5441 11.0275 11.2419C11.2542 10.9289 11.5887 10.5457 12.0314 10.0924C12.5494 9.57429 12.9434 9.11016 13.2133 8.7C13.483 8.27905 13.618 7.78794 13.618 7.22667C13.618 6.70857 13.4723 6.24984 13.1809 5.85048C12.9003 5.45112 12.5009 5.1381 11.9828 4.91143C11.4755 4.68476 10.8926 4.57143 10.2343 4.57143C9.60815 4.57143 8.98755 4.68476 8.37231 4.91143C7.75708 5.12731 7.22278 5.42953 6.76941 5.8181C6.51038 6.01238 6.38086 6.26603 6.38086 6.57905C6.38086 6.80572 6.44568 7.0054 6.5752 7.1781C6.71545 7.34 6.87732 7.42096 7.06091 7.42096C7.23352 7.42096 7.45483 7.3454 7.72461 7.19429C8.19958 6.88127 8.61511 6.64381 8.97131 6.48191C9.32751 6.30921 9.69995 6.22286 10.0885 6.22286C10.5419 6.22286 10.8979 6.33079 11.1571 6.54667C11.4269 6.75175 11.5618 7.03238 11.5618 7.38857C11.5618 7.71239 11.4862 8.00381 11.3352 8.26286C11.1841 8.52191 10.9465 8.84572 10.6228 9.23429C10.2126 9.72 9.89954 10.1679 9.68372 10.5781C9.4679 10.9883 9.35986 11.4902 9.35986 12.0838C9.35986 12.3105 9.41382 12.4886 9.52185 12.6181ZM9.14941 15.8724C9.3761 16.0991 9.66211 16.2124 10.0076 16.2124C10.3422 16.2124 10.6228 16.0991 10.8495 15.8724C11.0869 15.635 11.2056 15.3435 11.2056 14.9981C11.2056 14.6527 11.0923 14.3667 10.8656 14.14C10.6389 13.9026 10.3529 13.7838 10.0076 13.7838C9.66211 13.7838 9.3761 13.9026 9.14941 14.14C8.92273 14.3667 8.80945 14.6527 8.80945 14.9981C8.80945 15.3435 8.92273 15.635 9.14941 15.8724Z' />
    </svg>
  </Icon>
)
