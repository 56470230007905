import React, { useState, useEffect } from 'react'
import ViewSensorSidebar from '../components/view-sensor-sidebar'
import SensorForm from '../components/sensor-form'
import Head from '../components/head'
import qs from 'qs'
import merge from 'merge'

import { checkSensor } from '../validators'

export default ({ history, api }) => {
  const search = qs.parse(history.location.search, { ignoreQueryPrefix: true })
  const id = search.id
  const serviceName = search.service
  const [service, setService] = useState({ service: serviceName, read: false, write: false, remove: false, http: false })

  const [sensor, setSensor] = useState({
    id: null,
    offering: null,
    sitecode: null,
    networkdId: null,
    rid: null,
    fieldname: null,
    obs_pref_label: null,
    output_name: null,
    output_op_definition: null,
    units: null,
    foi: {
      id: null
    },
    lat: null,
    lon: null,
    name: '',
    desc: '',
    make: '',
    model: '',
    alt: 0,
    loading: true
  })

  const [fois, setFois] = useState([])
  const [isVisitor, setIsVisitor] = useState(true)
  const [changed, setChanged] = useState(false)

  const [serverErrors, setServerErrors] = useState({})

  let errors = {}
  const validation = checkSensor(sensor)
  if (validation !== true) {
    validation.forEach(({ field, message }) => {
      if (sensor[field] !== null) errors[field] = message
    })
  }

  errors = merge(true, errors, serverErrors)

  useEffect(() => {
    document.title = 'View Sensor'

    const onGetSensorReply = ({ sensor, role, error, field }) => {
      if (error) {
        const serverErrors = {}
        serverErrors[field] = error
        setServerErrors(serverErrors)
        setSensor({ id, foi: {}, loading: false })
      } else {
        setSensor(sensor)
        setFois([sensor.foi])
        setIsVisitor(role === 'visitor')
        api.emit('get-fois', { service: serviceName })
      }
    }
    api.on('get-sensor-reply', onGetSensorReply)

    const onGetServicesReply = (services) => {
      const { service, read, write, remove, http } = services.filter(({ service }) => service === serviceName)[0]
      setService({
        service,
        read: read === '',
        write: write === '',
        remove: remove === '',
        http: http === 'true'
      })
    }
    api.on('get-services-reply', onGetServicesReply)

    const onUpdate = (res) => {
      api.emit('get-sensor', { service: serviceName, id })
    }

    api.on('connect', onUpdate)
    api.on('user-updated', onUpdate)
    api.on('services-updated', onUpdate)
    api.on('foi-updated', onUpdate)
    api.on('sensor-updated', onUpdate)

    const onGetFoisReply = ({ fois }) => { setFois(fois) }
    api.on('get-fois-reply', onGetFoisReply)

    const onSensorReply = ({ sensor, error, field }) => {
      setChanged(false)
      if (error) {
        const serverErrors = {}
        serverErrors[field] = error
        setServerErrors(serverErrors)
      } else {
        history.push(`/view/sensor?id=${sensor.id}&service=${serviceName}`)
      }
    }
    api.on('sensor-reply', onSensorReply)

    const onRemoveSensorReply = () => {
      history.push('/')
    }
    api.on('remove-sensor-reply', onRemoveSensorReply)

    if (sensor.loading) {
      api.emitUntilResponse('get-sensor', { service: serviceName, id }, 'get-sensor-reply')
      api.emitUntilResponse('get-services', {}, 'get-services-reply')
    }

    return () => {
      api.off('get-sensor-reply', onGetSensorReply)
      api.off('get-fois-reply', onGetFoisReply)
      api.off('sensor-reply', onSensorReply)
      api.off('connect', onUpdate)
      api.off('user-updated', onUpdate)
      api.off('services-updated', onUpdate)
      api.off('foi-updated', onUpdate)
      api.off('sensor-updated', onUpdate)
      api.off('get-services-reply', onGetServicesReply)
      api.off('remove-sensor-reply', onRemoveSensorReply)
    }
  })

  return (
    <div>
      <Head>{id}</Head>
      <div className='columns'>
        <ViewSensorSidebar
          canSave={service.write && changed}
          canRemove={service.remove}
          remove={() => {
            api.emit('remove-sensor', { service: serviceName, id: sensor.id })
            history.push('/transactions')
          }}
          readOnly={isVisitor || !service.write}
          sensor={sensor}
          service={serviceName}
          errors={errors}
          save={() => {
            api.emit('update-sensor', { sensor, service: serviceName })
            history.push('/transactions')
          }}
        />
        <SensorForm
          readOnly={isVisitor || !service.write}
          isUpdate
          errors={errors}
          service={serviceName}
          sensor={sensor}
          fois={fois}
          onChange={(update) => {
            setChanged(true)
            setServerErrors({})
            setSensor(merge(true, sensor, update))
          }}
        />
      </div>
    </div>
  )
}
