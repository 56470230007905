import React from 'react'

const InputText = ({ name, message, danger, disabled, readOnly, type = 'text', label, icon = null, defaultValue, onChange = () => {}, value, placeholder, tour }) => {
  return (
    <div className={`field input-text ${disabled ? 'is-disabled' : ''}`} data-tour={tour}>
      <div className='field-label'>
        <label className='label' htmlFor={name}>{label}</label>
      </div>
      <div className='field-body'>
        <div className='field'>
          <div className={`control ${icon !== null ? 'has-icons-left' : ''}`}>
            {icon}
            <input
              id={name}
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              type={type}
              className={`input ${danger ? 'is-danger' : ''}`}
              defaultValue={defaultValue}
              onChange={(evt) => { onChange(evt.target.value) }}
              value={value === null || typeof value === 'undefined' ? '' : value}
            />
          </div>
          {message ? <p className='help is-danger'>{message}</p> : null}
        </div>
      </div>
    </div>
  )
}

export default InputText
