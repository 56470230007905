import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='download'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M15.1969 8.55866L11.0139 14.7687C10.6176 15.3571 9.75159 15.3571 9.35522 14.7687L5.17224 8.55866C4.72485 7.89443 5.20081 7 6.00171 7H9.18457V1C9.18457 0.447716 9.63232 0 10.1846 0C10.7368 0 11.1846 0.447716 11.1846 1V7H14.3674C15.1683 7 15.6443 7.89443 15.1969 8.55866ZM2 11C2 10.4477 1.55225 10 1 10C0.447754 10 0 10.4477 0 11V19C0 19.5523 0.447754 20 1 20H19C19.5522 20 20 19.5523 20 19V11C20 10.4477 19.5522 10 19 10C18.4478 10 18 10.4477 18 11V18H2V11Z' />
    </svg>

  </Icon>
)
