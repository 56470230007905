import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='square'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0 1C0 0.447708 0.447723 0 1 0H19C19.5523 0 20 0.447708 20 1V19C20 19.5523 19.5523 20 19 20H1C0.447723 20 0 19.5523 0 19V1ZM18 2H2V18H18V2Z' />
    </svg>
  </Icon>
)
