import React from 'react'

import Title from './title'
import InputText from './input-text'
import Checkbox from './checkbox'
import SearchIcon from './icons/search-icon'

const TransactionsSidebar = ({
  canViewUsers,
  onChange,
  filters = {
    services: {}
  },
  services = []
}) => {
  return (
    <div className='sidebar column is-3'>
      <div data-tour='search'>
        <Title>Search</Title>
        {canViewUsers
          ? (
            <InputText
              name='users'
              onChange={(value) => { onChange({ users: value }) }}
              label='Users'
              icon={<SearchIcon />}
              value={filters.users || ''}
            />
          )
          : null}
        <InputText
          name='id'
          onChange={(value) => { onChange({ id: value }) }}
          label='ID'
          icon={<SearchIcon />}
          value={filters.id || ''}
        />
      </div>

      <div data-tour='by-status'>
        <Title>Filter by Status</Title>
        <Checkbox
          onChange={(value) => { onChange({ status: { done: value } }) }}
          checked={filters.status.done}
        >
          <span>Done</span>
        </Checkbox>
        <Checkbox
          onChange={(value) => { onChange({ status: { error: value } }) }}
          checked={filters.status.error}
        >
          <span>Error</span>
        </Checkbox>
        <Checkbox
          onChange={(value) => { onChange({ status: { 'in-progress': value } }) }}
          checked={filters.status['in-progress']}
        >
          <span>In Progress</span>
        </Checkbox>
      </div>
      <div data-tour='by-type'>
        <Title>Filter by Type</Title>
        <Checkbox
          onChange={(value) => { onChange({ types: { insert: value } }) }}
          checked={filters.types.insert}
        >Insert
        </Checkbox>
        <Checkbox
          onChange={(value) => { onChange({ types: { update: value } }) }}
          checked={filters.types.update}
        >Update
        </Checkbox>
        <Checkbox
          onChange={(value) => { onChange({ types: { delete: value } }) }}
          checked={filters.types.delete}
        >Delete
        </Checkbox>
      </div>
      <div data-tour='by-service'>
        <Title>Filter by Service</Title>
        {services.map(({ name }) => {
          return (
            <Checkbox
              onChange={(value) => {
                filters.services[name] = value
                onChange({ filters })
              }}
              key={name}
              checked={filters.services[name] || true}
            >{name}
            </Checkbox>
          )
        })}
      </div>
    </div>
  )
}

export default TransactionsSidebar
