import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='profile'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47717 0 0 4.47715 0 10C0 15.5228 4.47717 20 10 20ZM14.7814 16.533C16.7906 15.06 18.0952 12.6824 18.0952 10C18.0952 5.52913 14.4709 1.90476 10 1.90476C5.52917 1.90476 1.90479 5.52913 1.90479 10C1.90479 12.6823 3.20935 15.06 5.21863 16.533C5.84546 14.4874 7.74902 13 10 13C12.251 13 14.1545 14.4874 14.7814 16.533ZM12.9642 17.5353C12.741 16.0991 11.4988 15 10 15C8.50122 15 7.25903 16.0991 7.03577 17.5353C7.95374 17.8967 8.95374 18.0952 10 18.0952C11.0463 18.0952 12.0463 17.8967 12.9642 17.5353ZM10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6C8.89539 6 8 6.89543 8 8C8 9.10457 8.89539 10 10 10ZM10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79089 4 6 5.79086 6 8C6 10.2091 7.79089 12 10 12Z' />
    </svg>
  </Icon>
)
