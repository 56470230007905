import React, { useState, useEffect } from 'react'
import merge from 'merge'

import Head from '../components/head'
import InsertDataSidebar from '../components/insert-data-sidebar'
import FilednameMetaData from '../components/fieldname-meta-data'
import UploadData from '../components/upload-data'
import Panel from '../components/panel'
import Button from '../components/button'
import TimesIcon from '../components/icons/times-icon'
import CheckIcon from '../components/icons/check-icon'
import WarningIcon from '../components/icons/warning-icon'
import InfoIcon from '../components/icons/info-icon'

import { checkFoi } from '../validators'

import SensorForm from '../components/sensor-form'

const Upload = ({
  errors,
  service,
  api,
  setMetaData,
  metaData,
  keys,
  isNewKey,
  file,
  uploadData,
  fois,
  setFileErrors,
  setFile,
  setUploadData,
  isUploading,
  setIsUploading
}) => (
  <div className='insert-form'>
    <FilednameMetaData
      data={metaData}
      update={(metaData) => {
        setMetaData(metaData)
        api.emitDebounced('update-fieldname-meta-data', { service, metaData })
      }}
      remove={(index) => {
        const newData = metaData.filter((_, i) => i !== index)
        setMetaData(newData)
        api.emit('update-fieldname-meta-data', { service, metaData: newData })
      }}
      disabled={service === null}
    />
    <UploadData
      isNewKey={isNewKey}
      keys={keys}
      isUploading={isUploading}
      uploadData={uploadData}
      file={file}
      disabled={service === null}
      errors={errors}
      foi={uploadData.foi}
      fois={fois}
      onFileChange={(file) => {
        setFileErrors([])
        setFile(file)
      }}
      onChange={(change) => { setUploadData(merge.recursive(true, uploadData, change)) }}
      upload={() => {
        setIsUploading(true)
        api.emit('upload-data', { service, ...uploadData, file, filename: file.name })
      }}
      setFileErrors={(errors) => {
        setFileErrors(errors)
      }}
    />
  </div>
)

const ConfirmationMessageNoFoi = () => (
  <div className='confirmation-sensor-message-container'>
    <p className='confirmation-sensor-message is-danger'>
      <WarningIcon />
      <span>Could not automatically select a Feature of Interest</span>
    </p>
    <p className='confirmation-sensor-subtext'>You need to "CANCEL" and re-upload the file, this time select a Feature of Interest</p>
  </div>
)

const ConfirmationMessageNothingToInsert = () => (
  <div className='confirmation-sensor-message-container'>
    <p className='confirmation-sensor-message'>
      <InfoIcon />
      <span>All the data from this file has already been entered</span>
    </p>
  </div>
)

const ConfirmationMessageSensorExists = () => (
  <div className='confirmation-sensor-message-container'>
    <p className='confirmation-sensor-message is-warning'>
      <InfoIcon />
      <span>Adding values to existing sensor</span>
    </p>
  </div>
)

const ConfirmationMessageMissingFoi = ({ sensor }) => (
  <div className='confirmation-sensor-message-container'>
    <p className='confirmation-sensor-message'>
      <InfoIcon />
      <span>Automatically selected Feature of Interest</span>
    </p>
    <p className='confirmation-sensor-subtext'>
      <span>This sensor does not yet exist</span>
      <br />
      <span><b>{sensor.foi.id}</b> has been selected</span>
    </p>
  </div>
)

const ConfirmationMessageMissingMetadata = ({ sensor, field }) => (
  <div className='confirmation-sensor-message-container'>
    <p className='confirmation-sensor-message is-danger'>
      <WarningIcon />
      <span>Missing {field} in Metadata for {sensor.fieldname}</span>
    </p>
    <p className='confirmation-sensor-subtext'>If you continue no data will be entered for this sensor</p>
  </div>
)

const ConfirmationSensorError = ({ sensor, errors, exists, values, noFoi }) => {
  if (noFoi) return <ConfirmationMessageNoFoi sensor={sensor} />
  if (values.length === 0) return <ConfirmationMessageNothingToInsert sensor={sensor} />
  if (errors.metadata === false) return <ConfirmationMessageMissingMetadata sensor={sensor} field='all' />
  if (errors.observedPreferenceLabel === false) return <ConfirmationMessageMissingMetadata sensor={sensor} field='"Observed Preference Label"' />
  if (errors.observableProperty === false) return <ConfirmationMessageMissingMetadata sensor={sensor} field='"Observed Property"' />
  if (errors.units === false) return <ConfirmationMessageMissingMetadata sensor={sensor} field='"Units"' />
  if (errors.derivedFoi) return <ConfirmationMessageMissingFoi sensor={sensor} />
  if (exists) return <ConfirmationMessageSensorExists sensor={sensor} />
  return null
}

const ConfirmationSensor = ({ sensor, errors = {}, values, exists, onRemove, noFoi }) => {
  return (
    <div className='confirmation-sensor'>

      <div>
        <p className='confirmation-sensor-id'>{sensor.id}</p>
        {values.length > 0 ? <p className='confirmation-sensor-size'>Inserting {values.length} new values</p> : null}
      </div>
      <div>
        <ConfirmationSensorError sensor={sensor} errors={errors} exists={exists} values={values} noFoi={noFoi} />
      </div>

      <Panel label='Sensor Details'>
        <SensorForm readOnly sensor={sensor} fois={[]} onChange={() => {}} errors={{}} />
      </Panel>
      <div className='columns'>
        <div className='column is-3'>
          <Button danger onClick={() => onRemove()}>
            <TimesIcon />
            <span>DO NOT INSERT</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

const Confirmation = ({ sensors, onCancel, remove, upload, noFoi }) => {
  return (
    <div className='confirmation'>
      <div className='confirmation-sensors'>
        {sensors.map(({ sensor, errors, values, exists }, index) => (
          <ConfirmationSensor
            onRemove={() => { remove(index) }}
            key={`sensor-${sensor.id || index}`}
            sensor={sensor}
            exists={exists}
            errors={errors}
            values={values}
            noFoi={noFoi}
          />
        )
        )}

      </div>
      <div className='columns confirmation-buttons'>
        <div className='column is-half' />
        <div className='column'>
          <Button onClick={upload} disabled={noFoi}>
            <CheckIcon />
            <span>CONFIRM</span>
          </Button>
        </div>
        <div className='column'>
          <Button danger onClick={onCancel}>
            <TimesIcon />
            <span>CANCEL</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ({ history, api, profile }) => {
  const [services, setServices] = useState([])
  const [service, setService] = useState(null)
  const [metaData, setMetaData] = useState([])
  const [keys, setKeys] = useState([])
  const [isNewKey, setIsNewKey] = useState(false)
  const [uploadData, setUploadData] = useState({ foi: {} })
  const [isUploading, setIsUploading] = useState(false)

  const [fois, setFois] = useState([])
  const [file, setFile] = useState(null)
  const [sensors, setSensors] = useState([])
  const [noFoi, setNoFoi] = useState(false)

  const [serverErrors] = useState({})
  const [fileErrors, setFileErrors] = useState([])
  let errors = {}

  if (file === null) errors.file = 'File is required'

  if (uploadData.foi.isNew) {
    const foiErrors = checkFoi(uploadData.foi)
    if (foiErrors !== true) {
      foiErrors.forEach(({ field, message }) => {
        errors.foi = errors.foi || {}
        if (uploadData.foi[field] !== null) errors.foi[field] = message
      })
    }
  }
  errors = merge(true, errors, serverErrors)
  if (fileErrors.length > 0) errors.file = fileErrors.join('\n')

  useEffect(() => {
    document.title = 'Insert Data'

    const onGetUserServices = (services) => { setServices(services) }
    api.on('get-user-services-reply', onGetUserServices)

    const onGetFieldnameMetaDataReply = (metaData) => {
      setMetaData(metaData.data)
      setKeys(metaData.keys)
    }
    api.on('get-fieldname-meta-data-reply', onGetFieldnameMetaDataReply)

    const onGetFoisReply = ({ fois }) => { setFois(fois) }
    api.on('get-fois-reply', onGetFoisReply)

    const onUpdate = () => {
      api.emit('get-user-services')
      if (service !== null) {
        api.emit('get-fieldname-meta-data', { service })
        api.emit('get-fois', { service })
      }
    }
    api.on('connect', onUpdate)
    api.on('user-updated', onUpdate)
    api.on('services-updated', onUpdate)

    const onUploadDataReply = ({ sensors, noFoi }) => {
      setSensors(sensors)
      setNoFoi(noFoi)
    }
    api.on('upload-data-reply', onUploadDataReply)

    const onInsertDataReply = () => {
      history.push('/transactions')
    }
    api.on('insert-data-reply', onInsertDataReply)

    if (services.length === 0) api.emitUntilResponse('get-user-services', {}, 'get-user-services-reply')

    return () => {
      api.off('get-fieldname-meta-data-reply', onGetFieldnameMetaDataReply)
      api.off('get-fois-reply', onGetFoisReply)
      api.off('get-user-services-reply', onGetUserServices)
      api.off('connect', onUpdate)
      api.off('user-updated', onUpdate)
      api.off('services-updated', onUpdate)
      api.off('upload-data-reply', onUploadDataReply)
      api.off('insert-data-reply', onInsertDataReply)
    }
  })

  return (
    <div>
      <Head>Upload Data</Head>
      {!profile.loggedIn ? (
        <div>
          <p className='content has-text-centered'>You need to be logged in to upload files</p>
        </div>
      )
        : (
          <div className='columns'>
            <InsertDataSidebar
              errors={errors}
              services={services}
              upload={() => {}}
              service={service}
              setService={(service) => {
                api.emit('get-fieldname-meta-data', { service })
                api.emit('get-fois', { service })
                setService(service)
              }}
            />
            <div className='column insert-data' data-tour='information'>
              {sensors.length > 0 ? (
                <Confirmation
                  noFoi={noFoi}
                  sensors={sensors}
                  service={service}
                  api={api}
                  fois={fois}
                  upload={() => {
                    api.emit('insert-data', { service, sensors, filename: file.name })
                    history.push('/transactions')
                  }}
                  onCancel={() => {
                    setIsUploading(false)
                    setUploadData({ foi: {} })
                    setFile(null)
                    setNoFoi(false)
                    setSensors([])
                  }}
                  remove={(index) => {
                    const newSensors = sensors.filter((sensor, i) => index !== i)
                    if (newSensors.length === 0) {
                      setUploadData({ foi: {} })
                      setFile(null)
                      setNoFoi(false)
                    }
                    setSensors(newSensors)
                  }}
                />
              ) : (
                <Upload
                  errors={errors}
                  service={service}
                  api={api}
                  setMetaData={setMetaData}
                  metaData={metaData}
                  keys={keys}
                  isNewKey={isNewKey}
                  file={file}
                  uploadData={uploadData}
                  fois={fois}
                  setFileErrors={setFileErrors}
                  setFile={setFile}
                  setUploadData={(data) => {
                    setUploadData(data)
                    if (data.key === '_new_key') setIsNewKey(true)
                    else if (keys.includes(data.key)) setIsNewKey(false)
                  }}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                />
              )}
            </div>
          </div>
        )}
    </div>
  )
}
