
import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='logout'>
    <svg width='20' height='16' viewBox='0 0 20 16' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M16.7089 12.1032C19.1422 9.66978 19.1422 5.72451 16.7089 3.29115C14.2755 0.857777 10.3302 0.857793 7.89685 3.29115C7.61056 3.57744 7.14642 3.57743 6.86014 3.29115C6.57386 3.00487 6.57385 2.54074 6.86014 2.25445C9.86609 -0.751483 14.7396 -0.751483 17.7456 2.25445C20.7515 5.26038 20.7515 10.1339 17.7456 13.1399C14.7396 16.1458 9.86609 16.1458 6.86014 13.1399C6.57386 12.8535 6.57385 12.3894 6.86014 12.1032C7.14644 11.8169 7.61057 11.8169 7.89685 12.1032C10.3302 14.5365 14.2755 14.5365 16.7089 12.1032ZM0.304383 8.45232C-0.101461 8.14428 -0.101461 7.53408 0.304383 7.22606L4.40965 4.11038C4.91651 3.7257 5.6447 4.0872 5.6447 4.72351V7.06948H13.0726C13.4977 7.06948 13.8423 7.41409 13.8423 7.83916C13.8423 8.26428 13.4977 8.60888 13.0726 8.60888H5.6447V10.9549C5.6447 11.5912 4.91651 11.9527 4.40965 11.568L0.304383 8.45232Z' />
    </svg>
  </Icon>
)
