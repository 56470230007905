import React from 'react'
import merge from 'merge'

import InputText from './input-text'

const ServiceToken = ({ service, setService }) => (
  <InputText
    name={`${service} service token`}
    disabled={service.saving}
    label='Token'
    type='password'
    value={service.token || ''}
    onChange={(value) => {
      setService(merge(true, service, { token: value, changed: true, saved: false }))
    }}
  />
)

export default ServiceToken
