import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='valid-list'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M2 18V2H18V3C18 3.55228 18.4477 4 19 4C19.5523 4 20 3.55228 20 3V1C20 0.447723 19.5523 0 19 0H1C0.447693 0 0 0.447723 0 1V19C0 19.5523 0.447693 20 1 20H3C3.55231 20 4 19.5523 4 19C4 18.4477 3.55231 18 3 18H2ZM4 6C4 5.44772 4.44769 5 5 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H5C4.44769 7 4 6.55228 4 6ZM5 9C4.44769 9 4 9.44772 4 10C4 10.5523 4.44769 11 5 11H9C9.55231 11 10 10.5523 10 10C10 9.44772 9.55231 9 9 9H5ZM4 14C4 13.4477 4.44769 13 5 13H6C6.55231 13 7 13.4477 7 14C7 14.5523 6.55231 15 6 15H5C4.44769 15 4 14.5523 4 14ZM19.5088 8.85156C19.972 9.15234 20.1036 9.77167 19.8028 10.2349L14.5848 18.2699C14.5554 18.3266 14.5201 18.3813 14.4789 18.433L14.4606 18.4611C14.3659 18.607 14.2396 18.7199 14.0966 18.7969C13.7491 18.9948 13.3014 18.9719 12.9719 18.705L9.02502 15.5089C8.59583 15.1613 8.5296 14.5316 8.8772 14.1024L8.97247 13.9848C9.32001 13.5556 9.94971 13.4894 10.379 13.8369L13.3408 16.2354L17.9985 9.06311C18.2993 8.59991 18.9186 8.46829 19.3818 8.76907L19.5088 8.85156Z' />
    </svg>
  </Icon>
)
