import Validator from 'fastest-validator'

const strSchema = {
  type: 'string'
}

const requiredStrSchema = {
  ...strSchema,
  empty: false
}

const ncNameSchema = {
  ...strSchema,
  pattern: /^[a-zA-Z_]([a-zA-Z0-9_\-.]*)$/
}

const requiredNcNameSchema = {
  ...ncNameSchema,
  empty: false
}

const numSchema = {
  type: 'number',
  convert: true
}

const requiredNumSchema = {
  ...numSchema,
  optional: false
}

const validator = new Validator()

export const checkSensor = validator.compile({
  id: requiredStrSchema,
  offering: requiredStrSchema,
  obs_pref_label: requiredStrSchema,
  output_name: requiredNcNameSchema,
  units: requiredStrSchema,
  output_op_definition: requiredStrSchema,
  sitecode: strSchema,
  fieldname: strSchema,
  name: strSchema,
  desc: strSchema,
  make: strSchema,
  model: strSchema,
  alt: numSchema
})

export const checkFoi = validator.compile({
  id: requiredStrSchema,
  name: strSchema,
  uri: requiredStrSchema,
  lat: requiredNumSchema,
  lon: requiredNumSchema
})

export const checkService = validator.compile({
  networkId: requiredStrSchema,
  name: requiredStrSchema,
  url: {
    type: 'url'
  }
})

export const checkEmail = validator.compile({
  email: {
    type: 'email',
    empty: false
  }
})

export const checkFieldnameMetadata = validator.compile({
  fieldname: requiredNcNameSchema,
  observableProperty: requiredStrSchema,
  observedPreferenceLabel: requiredStrSchema,
  units: requiredStrSchema,
  output: requiredStrSchema
})
