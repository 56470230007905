import auth0 from 'auth0-js'
import Cookies from 'js-cookie'

const auth = ({ domain, clientID, redirectUri }) => {
  let accessToken = null
  let idToken = null
  let expiresAt = 0

  const auth = new auth0.WebAuth({
    domain,
    clientID,
    redirectUri,
    responseType: 'token id_token',
    scope: 'openid profile'
  })

  const setSession = (authResult) => {
    Cookies.set('isLoggedIn', true)

    expiresAt = (authResult.expiresIn * 1000) + new Date().getTime()
    accessToken = authResult.accessToken
    idToken = authResult.idToken
  }

  const handleAuthentication = () => new Promise((resolve, reject) => {
    auth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setSession(authResult)
        resolve()
      } else if (err) reject(err)
      else reject(new Error('can not authenticate'))
    })
  })

  const getAccessToken = () => accessToken
  const getIdToken = () => idToken

  const renewSession = () => new Promise((resolve, reject) => {
    auth.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setSession(authResult)
        resolve()
      } else if (err) {
        logout()
        reject(err)
      }
    })
  })

  const login = (returnTo) => {
    Cookies.set('returnTo', returnTo)
    setTimeout(() => auth.authorize(), 100)
  }

  const logout = () => {
    accessToken = null
    idToken = null
    expiresAt = 0

    Cookies.remove('isLoggedIn')
    auth.logout({
      returnTo: window.location.origin
    })
  }

  const isAuthenticated = () => new Date().getTime() < expiresAt

  const isLoggedIn = () => Cookies.get('isLoggedIn') === 'true'

  const getProfile = () => new Promise((resolve, reject) => {
    auth.client.userInfo(accessToken, (err, profile) => {
      if (err) reject(err)
      else {
        profile.loggedIn = isLoggedIn()
        resolve(profile)
      }
    })
  })

  return {
    handleAuthentication,
    login,
    logout,
    isAuthenticated,
    isLoggedIn,
    getAccessToken,
    getIdToken,
    renewSession,
    getProfile
  }
}

export default auth
