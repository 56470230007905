import React, { useState, useEffect } from 'react'
import merge from 'merge'
import Head from '../components/head'
import InsertSidebar from '../components/insert-sidebar'
import SensorForm from '../components/sensor-form'

import { checkSensor, checkFoi } from '../validators'

const id = (sensor) => sensor.fieldname ? `/${sensor.version}/${sensor.networkId}/${sensor.sitecode}/${sensor.fieldname}${sensor.key ? `/${sensor.key}` : ''}${sensor.rid && sensor.key !== sensor.rid ? `/${sensor.rid}` : ''}` : ''

export default ({
  history,
  api,
  profile
}) => {
  const [services, setServices] = useState([])
  const [service, setService] = useState(null)
  const [fois, setFois] = useState([])
  const [sensor, setSensor] = useState({
    id: null,
    key: null,
    networkId: null,
    offering: null,
    sitecode: null,
    fieldname: null,
    rid: null,
    name: null,
    desc: null,
    make: null,
    model: null,
    obs_pref_label: null,
    output_name: null,
    output_op_definition: null,
    units: null,
    foi: {
      id: null,
      isNew: false,
      name: null,
      uri: null,
      lat: null,
      lon: null
    },
    lat: null,
    lon: null,
    alt: null
  })

  const [serverErrors, setServerErrors] = useState({})
  let errors = {}
  const foi = (sensor.foi)
  const sensorErrors = checkSensor(sensor)
  if (sensorErrors !== true) {
    sensorErrors.forEach(({ field, message }) => {
      if (sensor[field] !== null) errors[field] = message
    })
  }

  if (foi.isNew) {
    const foiErrors = checkFoi(foi)
    if (foiErrors !== true) {
      foiErrors.forEach(({ field, message }) => {
        errors.foi = errors.foi || {}
        if (sensor.foi[field] !== null) errors.foi[field] = message
      })
    }
  }
  errors = merge(true, errors, serverErrors)

  useEffect(() => {
    document.title = 'Insert Sensor'

    const onGetUserServices = (services) => { setServices(services) }
    api.on('get-user-services-reply', onGetUserServices)

    const onGetFoisReply = ({ fois }) => { setFois(fois) }
    api.on('get-fois-reply', onGetFoisReply)

    const onSensorReply = ({ service, error, field, isFoi }) => {
      if (error) {
        const serverErrors = {}
        if (isFoi) {
          serverErrors.foi = serverErrors.foi || {}
          serverErrors.foi[field] = error
        } else serverErrors[field] = error
        setServerErrors(serverErrors)
      } else {
        history.push(`/view/sensor?id=${id(sensor)}&service=${service}`)
      }
    }
    api.on('sensor-reply', onSensorReply)

    const onUpdate = () => { api.emit('get-user-services') }
    api.on('connect', onUpdate)
    api.on('user-updated', onUpdate)
    api.on('services-updated', onUpdate)

    if (services.length === 0) api.emitUntilResponse('get-user-services', {}, 'get-user-services-reply')

    return () => {
      api.off('get-user-services-reply', onGetUserServices)
      api.off('get-fois-reply', onGetFoisReply)
      api.off('sensor-reply', onSensorReply)
      api.off('connect', onUpdate)
      api.off('user-updated', onUpdate)
      api.off('services-updated', onUpdate)
    }
  })

  return (
    <div>
      <Head>Insert Sensor</Head>
      {!profile.loggedIn ? (
        <div>
          <p className='content has-text-centered'>You need to be logged in to insert a sensor</p>
        </div>
      )
        : (
          <div className='columns'>
            <InsertSidebar
              services={services}
              errors={errors}
              save={() => { api.emit('save-sensor', { sensor, service }) }}
              service={service}
              toSave={sensor}
              setService={service => {
                const { networkId } = services.filter(s => s.name === service)[0]
                setSensor({ ...sensor, networkId })
                api.emit('get-fois', { service })
                setService(service)
              }}
            />
            <SensorForm
              errors={errors}
              service={service}
              sensor={sensor}
              fois={fois}
              onChange={(update) => {
                setServerErrors({})
                setSensor(merge.recursive(true, sensor, update))
              }}
            />
          </div>
        )}
    </div>
  )
}
