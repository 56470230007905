import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='invalid-list'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M2 18V2H18V3C18 3.55228 18.4477 4 19 4C19.5523 4 20 3.55228 20 3V1C20 0.447723 19.5523 0 19 0H1C0.447693 0 0 0.447723 0 1V19C0 19.5523 0.447693 20 1 20H3C3.55231 20 4 19.5523 4 19C4 18.4477 3.55231 18 3 18H2ZM4 6C4 5.44772 4.44769 5 5 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H5C4.44769 7 4 6.55228 4 6ZM5 9C4.44769 9 4 9.44772 4 10C4 10.5523 4.44769 11 5 11H9C9.55231 11 10 10.5523 10 10C10 9.44772 9.55231 9 9 9H5ZM4 14C4 13.4477 4.44769 13 5 13H6C6.55231 13 7 13.4477 7 14C7 14.5523 6.55231 15 6 15H5C4.44769 15 4 14.5523 4 14ZM11.4 11.2929C11.7905 10.9024 12.4237 10.9024 12.8142 11.2929L15.5214 14.0002L18.2287 11.2929C18.6192 10.9024 19.2524 10.9024 19.6429 11.2929L19.7499 11.4C20.1405 11.7905 20.1405 12.4236 19.7499 12.8142L17.0427 15.5214L19.75 18.2287C20.1405 18.6192 20.1405 19.2524 19.75 19.6429L19.6429 19.75C19.2524 20.1405 18.6193 20.1405 18.2287 19.75L15.5214 17.0427L12.8141 19.75C12.4236 20.1405 11.7905 20.1405 11.4 19.75L11.2929 19.6429C10.9023 19.2524 10.9023 18.6192 11.2929 18.2287L14.0002 15.5214L11.2929 12.8142C10.9024 12.4236 10.9024 11.7905 11.2929 11.4L11.4 11.2929Z' />
    </svg>
  </Icon>
)
