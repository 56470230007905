import React, { useState } from 'react'
import FileIcon from './icons/file-icon'

const File = ({ onChange, disabled, message }) => {
  const [file, setFile] = useState({ name: 'No file selected' })
  return (
    <div>
      <div className={`file has-name ${disabled ? 'is-disabled' : ''}`}>
        <label className='file-label'>
          <input
            disabled={disabled}
            className='file-input'
            type='file'
            name='data'
            accept='.csv'
            onChange={(evt) => {
              const file = evt.target.files[0] || {}
              onChange(file)
              const name = file.name
              setFile({ name })
            }}
          />
          <span className='file-cta'>
            <span className='file-icon'>
              <FileIcon />
            </span>
            <span className='file-label'>Choose a file…</span>
          </span>
          <span className='file-name'>{file.name}</span>
        </label>
      </div>
      {message ? <p className='help is-danger'>{message}</p> : null}
    </div>
  )
}

export default File
