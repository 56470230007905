import React from 'react'
import Checkbox from './checkbox'
import SensorIcon from './icons/sensor-icon'
import MapPointIcon from './icons/map-point-icon'
import CheckIcon from './icons/check-icon'
import TimesIcon from './icons/times-icon'
import Button from './button'
import Title from './title'
import Subtitle from './subtitle'
import Loading from './loading'

const Filter = ({ params, children, name, title, loading, onParamChange }) => (
  <div data-tour={`filter-by-${name}`}>
    <Subtitle>{title}</Subtitle>
    <Button
      onClick={() => {
        Object.keys(params[name]).forEach(key => {
          params[name][key] = false
        })
        onParamChange(params)
      }}
      className='is-transparent is-small' fullwidth={false}
    >
      <TimesIcon />
      <span>CLEAR FILTERS</span>
    </Button>
    <Button
      onClick={() => {
        Object.keys(params[name]).forEach(key => {
          params[name][key] = true
        })
        onParamChange(params)
      }}
      className='is-transparent is-small' fullwidth={false}
    >
      <CheckIcon />
      <span>SELECT ALL</span>
    </Button>
    {loading === 0 ? <Loading>Finding available services ...</Loading> : null}
    <div>
      {children}
    </div>
  </div>
)

const FilterByMeta = ({ params, onParamChange, meta }) => {
  return (
    <div>{
      Object.keys(params[meta]).map((name) => {
        return (
          <Checkbox
            key={name}
            checked={params[meta][name]}
            onChange={(value) => {
              const params = {}
              params[meta] = {}
              params[meta][name] = value
              onParamChange(params)
            }}
          >{name}
          </Checkbox>
        )
      })
    }
    </div>
  )
}
export default ({
  all,
  services,
  onParamChange,
  params
}) => (
  <div className='sidebar column is-3'>

    {
      all ? (
        <Button
          tour='show-all'
          className='search-sidebar-button'
          onClick={() => {
            onParamChange({ showAll: true })
          }}
        >
          <CheckIcon />
          <span>SHOW ALL</span>
        </Button>
      ) : (
        <Button
          danger
          className='search-sidebar-button'
          onClick={() => {
            onParamChange({ clearAll: true, showAll: false })
          }}
        >
          <TimesIcon />
          <span>CLEAR</span>
        </Button>
      )
    }

    <Title>Filters</Title>
    <div className='filters'>
      <div data-tour='filter-by-type'>
        <Subtitle>Type</Subtitle>
        <Checkbox
          icon={<SensorIcon />}
          checked
          onChange={(value) => {
            onParamChange({ sensor: value })
          }}
        >
            Sensor
        </Checkbox>
        <Checkbox
          icon={<MapPointIcon />}
          checked
          onChange={(value) => {
            onParamChange({ foi: value })
          }}
        >
    Feature of Interest
        </Checkbox>
      </div>

      <Filter
        params={params}
        onParamChange={onParamChange}
        name='services'
        title='Services'
        loading={services.length === 0}
      >
        {services.map(({ service, read, http }) => (
          <Checkbox
            disabled={!http || read.length > 0}
            message={!http || read.length > 0 ? 'Unable to search this service' : ''}
            key={service}
            checked={params.services[service]}
            onChange={(value) => {
              const services = {}
              services[service] = value
              onParamChange({ services })
            }}
          >{service}
          </Checkbox>
        ))}
      </Filter>

      <Filter
        onParamChange={onParamChange}
        params={params}
        name='networkIds'
        title='Network ID'
        loading={services.length === 0}
      >

        <FilterByMeta
          params={params}
          meta='networkIds'
          onParamChange={onParamChange}
        />

      </Filter>
      <Filter
        onParamChange={onParamChange}
        params={params}
        name='fois'
        title='Features of Interest'
        loading={services.length === 0}
      >
        <FilterByMeta
          params={params}
          meta='fois'
          onParamChange={onParamChange}
        />
      </Filter>
      <Filter
        onParamChange={onParamChange}
        params={params}
        name='keys'
        title='Key'
        loading={services.length === 0}
      >
        <FilterByMeta
          params={params}
          meta='keys'
          onParamChange={onParamChange}
        />
      </Filter>
      <Filter
        onParamChange={onParamChange}
        params={params}
        name='fieldnames'
        title='Fieldname'
        loading={services.length === 0}
      >

        <FilterByMeta
          params={params}
          meta='fieldnames'
          onParamChange={onParamChange}
        />

      </Filter>
    </div>
  </div>
)
