import React, { useEffect } from 'react'
import Markdown from '../components/markdown'

const policy = `# Privacy Policy

## How we use cookies?

We use cookies for authorization to know how best to validate your login credentials *isLoggedIn*, *accepted*, and *returnTo*

## What personal data do we collect and how do we use it?

We use your [auth0 profile](https://manage.auth0.com/#/profile), this includes your email address and name, to verify what Sensor Services you have access to and how you can interact with them.
This includes whether you can read data, insert or modify the data in these services.
This also includes who can manage this access and what they can change.

Other than those who manage these Sensor Services we do not share this information with anyone else.

If you wish to remove your account please [Contact Us](link=/contact) and we will remove your account and all information from our databases.

## Third Party Cookies and Privacy Policies

Auth0 is the third-party tool we use for all authentication and you can find all the information about privacy here https://auth0.com/privacy

## How to Delete Cookies?

If you delete your cookies the site may not operate as expected, but if you wish to delete them please follow this guide https://www.aboutcookies.org/how-to-delete-cookies

## Getting in touch

If you wish to get in touch please [Contact Us](link=/contact). We will not keep correspondence for longer than required and will not share any information with any other party without prior consent.`

const PolicyPage = () => {
  useEffect(() => {
    document.title = 'Privacy Policy'
  })
  return <Markdown className='policy'>{policy}</Markdown>
}

export default PolicyPage
