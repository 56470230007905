import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='chevron'>
    <svg width='20' height='11' viewBox='0 0 20 11' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0.305533 0.390564C-0.101844 0.793345 -0.101844 1.44641 0.305533 1.84919L9.25557 10.6979C9.6524 11.0902 10.2894 11.1004 10.6987 10.7284C10.7379 10.6993 10.7754 10.6672 10.8112 10.6319L19.6945 1.84919C20.1018 1.44641 20.1018 0.793345 19.6945 0.390564L19.605 0.302086C19.1976 -0.100695 18.537 -0.100695 18.1297 0.302086L9.99999 8.3397L1.87035 0.302086C1.46297 -0.100695 0.802434 -0.100695 0.395057 0.302086L0.305533 0.390564Z' />
    </svg>
  </Icon>
)
