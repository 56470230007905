import React from 'react'
import SpinnerIcon from './icons/spinner-icon'
import CheckIcon from './icons/check-icon'
import TimesIcon from './icons/times-icon'

export default ({
  passed = false,
  failed = false,
  children
}) => {
  let icon = <SpinnerIcon />
  let type = 'spinner'
  if (passed) {
    type = 'passed'
    icon = <CheckIcon />
  }
  if (failed) {
    type = 'failed'
    icon = <TimesIcon />
  }

  return (
    <div className={`loading is-${type}`}>
      {icon}
      {children}
    </div>
  )
}
