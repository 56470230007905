import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import JoyRide from 'react-joyride'
import EmailIcon from './icons/email-icon'
import LockIcon from './icons/lock-icon'
import HelpIcon from './icons/help-icon'
import InfoIcon from './icons/info-icon'
import Button from './button'

import routeSteps from './route-steps.json'

export default ({ api, history }) => {
  const [version, setVersion] = useState('0.0.0')
  const [run, setRun] = useState(false)
  const [index, setStepIndex] = useState(0)

  let route = history.location.pathname
  if (route.length > 1 && route.endsWith('/')) route = route.slice(0, route.length - 1)

  const steps = routeSteps[route] || []

  useEffect(() => {
    const onGetVersionReply = (version) => { setVersion(version) }
    api.on('get-version-reply', onGetVersionReply)

    const onConnect = () => { api.emitUntilResponse('get-version', {}, 'get-version-reply') }
    api.on('connect', onConnect)

    if (version === '0.0.0') api.emitUntilResponse('get-version', {}, 'get-version-reply')
    return () => {
      api.off('get-version-reply', onGetVersionReply)
      api.off('connect', onConnect)
    }
  })

  return (
    <footer className='foot'>
      <div className='container'>
        <div>
          <Link className='foot-link' to='/contact'>
            <EmailIcon />
            <span>Contact</span>
          </Link>
          <Link className='foot-link' to='/policy'>
            <LockIcon />
            <span>Privacy Policy</span>
          </Link>
          <Link className='foot-link' to='/help'>
            <HelpIcon />
            <span>Help</span>
          </Link>
          {steps.length > 0
            ? (
              <Button fullwidth={false} link onClick={() => setRun(true)}>
                <InfoIcon />
                <span>Start tour</span>
              </Button>
            )
            : null}
        </div>
        <div className='version'>{version ? `v${version}` : null}</div>
      </div>
      <JoyRide
        showProgress
        locale={{ back: 'BACK', close: 'CLOSE', last: 'LAST', next: 'NEXT', skip: 'SKIP' }}
        continuous
        run={run}
        steps={steps}
        stepIndex={index}
        callback={({ action, index, status, type }) => {
          if (type === 'step:after') setStepIndex(index + (action === 'next' ? 1 : -1))
          if (action === 'reset' || action === 'close' || status === 'finished') {
            setRun(false)
            setStepIndex(0)
          }
        }}
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            overlayColor: 'rgba(0, 0, 0, 0.4)',
            primaryColor: 'rgba(50, 115, 220, 0.7)',
            textColor: '#4a4a4a'
          }
        }}
      />
    </footer>
  )
}
