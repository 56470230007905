import React from 'react'
import Button from './button'
import DownloadIcon from './icons/download-icon'
import ReuestIcon from './icons/request-icon'
import RefreshIcon from './icons/refresh-icon'
import SpinnerIcon from './icons/spinner-icon'

const RequestedButton = ({ loading }) => (
  <div>
    <Button disabled>
      <SpinnerIcon />
      <span>GETTING DATA</span>
    </Button>
    {!loading
      ? <p className='download-data-help'>Once the data is ready, a link will be available to download all the data</p>
      : null}
  </div>
)

const RequestButton = ({ onClick, canDownload }) => (
  <div>
    <Button onClick={onClick} disabled={!canDownload}>
      <ReuestIcon />
      <span>REQUEST DATA</span>
    </Button>
    <p className='download-data-help'>
    Once the data is ready, a link will be available to download all the data
    </p>
  </div>
)

const RefreshButton = ({ onClick, canDownload }) => (
  <div>
    <Button onClick={onClick} disabled={!canDownload}>
      <RefreshIcon />
      <span>REFRESH DOWNLOAD DATA</span>
    </Button>
    <p className='download-data-help'>
    Once the data is ready, a new link will be available to download all the data
    </p>
  </div>
)

const DownloadDataButton = ({ link, requested, onClick, loading, canDownload }) => {
  if (requested) return <RequestedButton loading={loading} />
  if (link) return <RefreshButton onClick={onClick} canDownload={canDownload} />
  return <RequestButton onClick={onClick} canDownload={canDownload} />
}

const DownloadDataFile = ({ link, created }) => (
  <div className='download-data-file'>
    <div className='download-data-file-link'>
      <a href={link} download>
        <DownloadIcon />
        <span>Download Data</span>
      </a>
    </div>
    <p className='content'>
      <small>This link was created at</small>
      <br />
      <span>{created.toLocaleString()}</span>
    </p>
  </div>
)

export default ({
  link,
  created,
  expires,
  onRequestData,
  requested,
  loading,
  canDownload
}) => (
  <div className='download-data' data-tour='data'>
    <DownloadDataButton
      canDownload={canDownload}
      loading={loading}
      requested={requested}
      onClick={onRequestData}
      link={link}
    />
    {!requested && link !== ''
      ? <DownloadDataFile link={link} created={created} />
      : null}
    {link !== ''
      ? (
        <p className='content download-data-expire'>
          <span className='has-text-danger'>Download before the link expires!</span>
          <br />
          <span>The download link will be removed at</span>
          <br />
          <span>{new Date(expires).toLocaleString()}</span>
        </p>
      )
      : null}
  </div>
)
