import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='arrow'>
    <svg width='11' height='20' viewBox='0 0 11 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M6.01396 0.436754C5.6176 -0.145585 4.75163 -0.145585 4.35527 0.436754L0.172284 6.58223C-0.275103 7.23955 0.200849 8.1247 1.00175 8.1247H4.18461V19.0104C4.18461 19.557 4.63237 20 5.18461 20C5.73686 20 6.18461 19.557 6.18461 19.0104V8.1247H9.36747C10.1684 8.1247 10.6443 7.23955 10.1969 6.58223L6.01396 0.436754Z' />
    </svg>

  </Icon>
)
