import React from 'react'

export default ({
  label,
  disabled,
  onChange,
  defaultValue,
  value,
  message,
  readOnly,
  name
}) => (
  <div className={`field ${disabled ? 'is-disabled' : ''}`}>
    <div className='field-label'>
      <label htmlFor={name} className='label'>{label}</label>
    </div>
    <textarea
      id={name}
      name={name}
      className='textarea'
      readOnly={readOnly}
      disabled={disabled}
      onChange={evt => { onChange(evt.target.value) }}
      defaultValue={defaultValue}
      value={value}
    />
    {message ? <p className='help is-danger'>{message}</p> : null}
  </div>
)
