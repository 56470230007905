import React, { useState, useEffect } from 'react'
import merge from 'merge'
import Head from '../components/head'
import SearchSidebar from '../components/search-sidebar'
import AutoSuggest from '../components/auto-suggest'

export default ({ api }) => {
  const [services, setSevices] = useState([])
  const [params, setParams] = useState({ term: '', sensor: true, foi: true, services: {}, showAll: false, clearAll: false, keys: {}, fois: {}, fieldnames: {}, networkIds: {} })
  const [suggestions, setSuggestions] = useState({ suggestions: [], loading: false })

  useEffect(() => {
    document.title = 'eLTER Central Data Node'

    const onServicesReply = (services) => {
      setSevices(services)
      const paramServices = {}
      services.forEach(({ service }) => {
        paramServices[service] = true
      })
      setParams(merge(true, params, { services: paramServices }))
      if (params.term !== '' || params.showAll) api.emitDebounced('search', params)
      api.emit('get-filters', { services, params })
    }
    api.on('get-services-reply', onServicesReply)

    const onSearchReply = res => {
      setSuggestions({ suggestions: res, loading: false })
    }
    api.on('search-reply', onSearchReply)

    const onGetFilterReply = (filters) => {
      const newParams = merge.recursive(true, {}, params)
      Object.entries(filters).forEach(([key, values]) => {
        newParams[key] = {}
        values.forEach(value => {
          newParams[key][value] = true
        })
      })
      setParams(newParams)
    }
    api.on('get-filters-reply', onGetFilterReply)

    const onUpdateReply = () => {
      api.emit('get-services')
      if (params.term !== '' || params.showAll) api.emit('search', params)
    }

    api.on('connect', onUpdateReply)
    api.on('user-updated', onUpdateReply)
    api.on('services-updated', onUpdateReply)
    api.on('foi-updated', onUpdateReply)
    api.on('sensor-updated', onUpdateReply)

    if (services.length === 0) api.emitUntilResponse('get-services', {}, 'get-services-reply')

    return () => {
      api.off('get-services-reply', onServicesReply)
      api.off('search-reply', onSearchReply)
      api.off('connect', onUpdateReply)
      api.off('user-updated', onUpdateReply)
      api.off('services-updated', onUpdateReply)
      api.off('foi-updated', onUpdateReply)
      api.off('sensor-updated', onUpdateReply)
      api.off('get-filters-reply', onGetFilterReply)
    }
  })

  return (
    <div>
      <Head label='eLTER Central Data Node' />
      <div className='columns'>
        <SearchSidebar
          params={params}
          all={suggestions.suggestions.length === 0 && params.term === ''}
          services={services}
          onParamChange={(newParams) => {
            const term = newParams.clearAll ? '' : params.term
            newParams = merge.recursive(true, params, newParams, { term })
            setParams(newParams)
            api.emit('search', newParams)
          }}
        />
        <AutoSuggest
          value={params.term} {...suggestions} onChange={(term) => {
            const newParams = merge.recursive(true, params, { term, showAll: false })
            setParams(newParams)
            setSuggestions({ suggestions: [], loading: term !== '' })
            api.emit('search', newParams)
          }}
        />
      </div>
    </div>
  )
}
