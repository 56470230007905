import React from 'react'

import Button from '../components/button'
import SaveIcon from '../components/icons/save-icon'
import ServicesSidebar from './services-sidebar'

// const InsertSidebar = ({ toSave, services, save, service, setService, errors }) => {
const InsertSidebar = ({ services, save, service, setService, errors }) => {
  const hasErrors = Object.keys(errors).length > 0
  // const missingValues = Object.values(toSave).filter(value => value === null).length > 0

  return (
    <ServicesSidebar
      services={services}
      service={service}
      setService={setService}
      errors={errors}
    >
      <Button
        tour='save'
        disabled={service === null || hasErrors}
        onClick={() => save(service)}
      >
        <SaveIcon />
        <span>SAVE</span>
      </Button>
    </ServicesSidebar>
  )
}

export default InsertSidebar
