import React from 'react'
import { Link } from 'react-router-dom'

import Title from './title'
import Radio from './radio'

import Loading from './loading'

const SidebarServices = ({
  services,
  service,
  setService,
  errors,
  children
}) => {
  const showMessage = services.filter(service => service.role !== 'visitor').length > 0 && service === null

  const canEdit = service => service.role === 'editor' || service.role === 'admin'
  const canInsert = service => service.valid.http && service.valid.write.length === 0

  return (
    <div className='sidebar column is-3'>
      <div data-tour='select-service'>
        <Title>Select Service</Title>
        {services.length === 0 ? <Loading>Loading Services ...</Loading> : null}
        <Radio
          items={services.map(service => ({
            value: service.name,
            disabled: !canEdit(service) || !canInsert(service),
            message: !canEdit(service) ? 'Registration required' : !canInsert(service) ? 'Unable to write to service' : null
          }))}
          onChange={(selectedService) => { setService(selectedService.value) }}
        />
      </div>
      {showMessage ? <p className='content is-small has-text-danger'>You need to select a service before you can save</p> : null}
      {children}
      {errors.main ? <p className='content is-small has-text-danger'>{errors.main}<br />If this error persists please <Link to='/contact'>send a message</Link></p> : null}
    </div>
  )
}

export default SidebarServices
