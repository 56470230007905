import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='cog'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1 0C0.447723 0 0 0.447708 0 1V19C0 19.5523 0.447723 20 1 20H19C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18H18V5C18 4.44771 17.5523 4 17 4C16.4477 4 16 4.44771 16 5V18H14V9C14 8.44771 13.5523 8 13 8C12.4477 8 12 8.44771 12 9V18H10V12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12V18H6V15C6 14.4477 5.55228 14 5 14C4.44772 14 4 14.4477 4 15V18H2V1C2 0.447708 1.55228 0 1 0Z' />
    </svg>
  </Icon>
)
