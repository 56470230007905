import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='circle'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47717 0 0 4.47715 0 10C0 15.5228 4.47717 20 10 20ZM10 18.0952C14.4709 18.0952 18.0952 14.4709 18.0952 10C18.0952 5.52913 14.4709 1.90476 10 1.90476C5.52917 1.90476 1.90479 5.52913 1.90479 10C1.90479 14.4709 5.52917 18.0952 10 18.0952Z' />
    </svg>
  </Icon>
)
