import React from 'react'
import { Link } from 'react-router-dom'

export default ({
  to = null,
  onClick = () => {},
  disabled = false,
  danger = false,
  className = '',
  children,
  fullwidth = true,
  style = {},
  link,
  tour
}) => {
  if (to) {
    return (
      <Link
        data-tour={tour}
        to={to}
        style={style}
        className={`button ${fullwidth ? 'is-fullwidth' : ''} ${className} ${danger ? 'is-danger' : ''} ${link ? 'is-href' : ''}`}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  }
  return (
    <button
      data-tour={tour}
      style={style}
      className={`button ${fullwidth ? 'is-fullwidth' : ''} ${className} ${danger ? 'is-danger' : ''} ${link ? 'is-href' : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
