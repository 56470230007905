import React, { useEffect } from 'react'
import Head from '../components/head'

const ThanksPage = () => {
  useEffect(() => {
    document.title = 'Thank You'
  })
  return (
    <div>
      <Head>Thank You</Head>
      <p className='content has-text-centered is-large'>Thank you for getting in touch</p>
      <p className='content has-text-centered'>
    We will try to respond with you as soon as possible
        <br />
    If the email you entered was valid you should receive a notification soon
      </p>
    </div>
  )
}

export default ThanksPage
