import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='check'>
    <svg width='20' height='18' viewBox='0 0 20 18' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M19.5203 0.18702C19.0227 -0.150146 18.3478 -0.0189362 18.0127 0.480063L8.59037 14.5115L1.73938 9.32668C1.26008 8.96396 0.579109 9.05967 0.218377 9.54047C-0.142322 10.0213 -0.0462349 10.7051 0.433069 11.0678L8.18689 16.9359C8.54217 17.2047 9.00832 17.2218 9.37268 17.0167C9.52745 16.9356 9.66482 16.8159 9.76925 16.6604L19.8146 1.70102C20.1497 1.20203 20.018 0.52417 19.5203 0.18702Z' />
    </svg>
  </Icon>
)
