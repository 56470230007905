import React from 'react'
import Icon from './icon'

const Search = () => (
  <Icon name='search'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M12.6316 12.1777C15.5383 12.1777 17.8947 9.90602 17.8947 7.10369C17.8947 4.30135 15.5383 2.02962 12.6316 2.02962C9.72479 2.02962 7.36838 4.30135 7.36838 7.10369C7.36838 9.90602 9.72479 12.1777 12.6316 12.1777ZM12.6316 14.2074C16.7011 14.2074 20 11.027 20 7.10369C20 3.18042 16.7011 0 12.6316 0C8.56206 0 5.26312 3.18042 5.26312 7.10369C5.26312 9.07602 6.09686 10.8606 7.44314 12.1477L6.80477 12.7632C6.44521 12.6583 6.03891 12.7429 5.75471 13.0169L0.308316 18.2675C-0.102772 18.6639 -0.102772 19.3064 0.308316 19.7028C0.719372 20.0991 1.38587 20.0991 1.79693 19.7028L7.24333 14.452C7.50995 14.195 7.60362 13.8343 7.52441 13.5045L8.24373 12.8111C9.46946 13.6884 10.9877 14.2074 12.6316 14.2074Z' />
    </svg>
  </Icon>
)

export default Search
