import React from 'react'

const Head = ({ label, children }) => {
  return (
    <div className='head'>
      <span className='head-label'>{label || children}</span>
      <hr />
    </div>
  )
}

export default Head
