import React, { useState } from 'react'
import CircleIcon from './icons/circle-icon'
import BullseyeIcon from './icons/bullseye-icon'

const Item = ({
  message,
  checked = false,
  children,
  onClick = () => {},
  disabled
}) => (
  <div className='checkbox-container'>
    <button disabled={disabled} className='button checkbox is-block' onClick={() => { onClick(!checked) }}>
      {!checked ? <CircleIcon /> : <BullseyeIcon />}
      <span className='checkbox-label'>{children}</span>
    </button>
    {message ? <p className='content is-small has-text-danger'>{message}</p> : null}
  </div>
)

export default ({
  className = '',
  items = [],
  onChange
}) => {
  const [value, setValue] = useState(null)
  return (
    <div className={`radio ${className}`}>
      {items.map(item => {
        return (
          <Item
            key={item.value}
            checked={item.value === value}
            message={item.message}
            disabled={item.disabled}
            onClick={() => {
              setValue(item.value)
              onChange(item)
            }}
          >{item.value}
          </Item>
        )
      })}
    </div>
  )
}
