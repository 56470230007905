import io from 'socket.io-client'
import debounce from 'debounce'

export const create = (auth, profile) => {
  const sockets = io(`${process.env.REACT_APP_API_HOST}?token=${auth.getAccessToken()}&name=${encodeURIComponent(profile.name)}`, { transports: ['websocket'] })
  const emit = (event, data = {}) => { sockets.emit(event, data) }
  const emitDebounced = debounce(emit)

  const emitUntilResponse = (event, data = {}, response, delay = 100) => {
    let retries = 0
    const interval = setInterval(() => {
      retries += 1
      if (process.env.NODE_ENV === 'development' && retries > 1) console.log(`retry ${event} -> ${response}: ${retries}`)
      if (retries >= 100) {
        clearInterval(interval)
        sockets.off(response, onResponse)
      } else emit(event, data)
    }, delay)
    const onResponse = () => {
      clearInterval(interval)
      sockets.off(response, onResponse)
    }
    sockets.on(response, onResponse)
    emit(event, data)
  }

  return {
    on: (event, fn) => {
      sockets.on(event, fn)
    },
    off: (event, fn) => {
      sockets.off(event, fn)
    },
    emit,
    emitUntilResponse,
    emitDebounced
  }
}

const noop = () => {}

export const mock = () => {
  return {
    on: noop,
    off: noop,
    emit: noop,
    emitUntilResponse: noop,
    emitDebounced: noop
  }
}
