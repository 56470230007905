import React from 'react'
import merge from 'merge'

import InputText from './input-text'

const ServiceNetworkId = ({ service, setService, error, disabled }) => (
  <InputText
    name={`${service} network id`}
    disabled={service.saving || disabled}
    danger={error}
    message={error}
    label='Network ID'
    value={service.networkId || ''}
    onChange={(value) => {
      try {
        setService(merge(true, service, { networkId: value, changed: true, saved: false }))
      } catch (err) {
        console.log(err)
      }
    }}
  />
)

export default ServiceNetworkId
