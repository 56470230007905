import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='request'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M2 18V2H18V3C18 3.55228 18.4477 4 19 4C19.5523 4 20 3.55228 20 3V1C20 0.447723 19.5523 0 19 0H1C0.447693 0 0 0.447723 0 1V19C0 19.5523 0.447693 20 1 20H3C3.55231 20 4 19.5523 4 19C4 18.4477 3.55231 18 3 18H2ZM4 6C4 5.44772 4.44769 5 5 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H5C4.44769 7 4 6.55228 4 6ZM5 9C4.44769 9 4 9.44772 4 10C4 10.5523 4.44769 11 5 11H9C9.55231 11 10 10.5523 10 10C10 9.44772 9.55231 9 9 9H5ZM4 14C4 13.4477 4.44769 13 5 13H6C6.55231 13 7 13.4477 7 14C7 14.5523 6.55231 15 6 15H5C4.44769 15 4 14.5523 4 14ZM15.1379 8.44135C15.5342 7.85287 16.4003 7.85287 16.7966 8.44135L19.7622 12.8441C20.2097 13.5083 19.7337 14.4027 18.9329 14.4027H16.7665V19.198C16.7665 19.6394 16.4086 19.9972 15.9673 19.9972C15.5259 19.9972 15.1681 19.6394 15.1681 19.198V14.4027H13.0017C12.2008 14.4027 11.7249 13.5083 12.1723 12.8441L15.1379 8.44135Z' />
    </svg>
  </Icon>
)
