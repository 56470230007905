import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='bullseye'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M20 10C20 15.5228 15.5228 20 10 20C4.47717 20 0 15.5228 0 10C0 4.47715 4.47717 0 10 0C15.5228 0 20 4.47715 20 10ZM18.0952 10C18.0952 14.4709 14.4709 18.0952 10 18.0952C5.52917 18.0952 1.90479 14.4709 1.90479 10C1.90479 5.52913 5.52917 1.90476 10 1.90476C14.4709 1.90476 18.0952 5.52913 18.0952 10ZM10 15C12.7615 15 15 12.7614 15 10C15 7.23858 12.7615 5 10 5C7.23853 5 5 7.23858 5 10C5 12.7614 7.23853 15 10 15Z' />
    </svg>
  </Icon>
)
