import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='files'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M2 2V13C2.55231 13 3 13.4477 3 14C3 14.5523 2.55231 15 2 15C0.895447 15 0 14.1046 0 13V2C0 0.895432 0.895447 0 2 0H13C14.1046 0 15 0.895432 15 2C15 2.55229 14.5523 3 14 3C13.4477 3 13 2.55229 13 2H2ZM7 7H18V18H7V7ZM5 7C5 5.89543 5.89545 5 7 5H18C19.1046 5 20 5.89543 20 7V18C20 19.1046 19.1046 20 18 20H7C5.89545 20 5 19.1046 5 18V7Z' />
    </svg>
  </Icon>
)
