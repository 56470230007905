import React, { useState, useEffect } from 'react'
import merge from 'merge'

import Head from '../components/head'
import InsertSidebar from '../components/insert-sidebar'
import FoiForm from '../components/foi-form'
import { checkFoi } from '../validators'

export default ({ history, api, profile }) => {
  const [services, setServices] = useState([])

  const [service, setService] = useState(null)
  const [foi, setFoi] = useState({
    id: null,
    name: null,
    uri: null,
    lat: null,
    lon: null
  })

  const [serverErrors, setServerErrors] = useState({})
  let errors = {}
  const validation = checkFoi(foi)
  if (validation !== true) {
    validation.forEach(({ field, message }) => {
      if (foi[field] !== null) errors[field] = message
    })
  }
  errors = merge(true, errors, serverErrors)

  useEffect(() => {
    document.title = 'Insert Feature of Interest'

    const onGetUserServices = (services) => { setServices(services) }
    const onFoiReply = ({ service, error, field }) => {
      if (error) {
        const serverErrors = {}
        serverErrors[field] = error
        setServerErrors(serverErrors)
      } else history.push(`/view/foi?id=${foi.id}&service=${service}`)
    }

    api.on('get-user-services-reply', onGetUserServices)
    api.on('foi-reply', onFoiReply)

    const onUpdate = () => { api.emit('get-user-services') }
    api.on('connect', onUpdate)
    api.on('user-updated', onUpdate)
    api.on('services-updated', onUpdate)

    if (services.length === 0) api.emitUntilResponse('get-user-services', {}, 'get-user-services-reply')

    return () => {
      api.off('get-user-services-reply', onGetUserServices)
      api.off('foi-reply', onFoiReply)
      api.off('connect', onUpdate)
      api.off('user-updated', onUpdate)
      api.off('services-updated', onUpdate)
    }
  })

  return (
    <div>
      <Head>Insert Feature of Interest</Head>
      {!profile.loggedIn ? (
        <div>
          <p className='content has-text-centered'>You need to be logged in to insert a feature of interest</p>
        </div>
      )
        : (
          <div className='columns'>
            <InsertSidebar
              toSave={foi}
              errors={errors}
              services={services}
              save={() => { api.emit('save-foi', { foi, service }) }}
              service={service}
              setService={setService}
            />
            <FoiForm
              errors={errors}
              service={service}
              foi={foi}
              onChange={(update) => {
                setServerErrors({})
                setFoi(merge(true, foi, update))
              }}
            />
      />
          </div>
        )}
    </div>
  )
}
