import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='login'>
    <svg width='16' height='20' viewBox='0 0 16 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M9 2H7C6.44771 2 6 2.44772 6 3V5H10V3C10 2.44772 9.55228 2 9 2ZM4 3V5H1C0.447715 5 0 5.44772 0 6V19C0 19.5523 0.447715 20 1 20H15C15.5523 20 16 19.5523 16 19V6C16 5.44772 15.5523 5 15 5H12V3C12 1.34315 10.6569 0 9 0H7C5.34315 0 4 1.34315 4 3ZM10 7H12H14V18H2V7H4H6H10ZM10 11C10 11.7403 9.59781 12.3867 9 12.7325V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V12.7325C6.40219 12.3867 6 11.7403 6 11C6 9.89542 6.89542 9 8 9C9.10458 9 10 9.89542 10 11Z' />
    </svg>
  </Icon>
)
