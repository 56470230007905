import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='upload'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M9.35522 0.44134C9.75159 -0.147113 10.6176 -0.147114 11.0139 0.441339L15.1969 6.65134C15.6443 7.31557 15.1683 8.21001 14.3674 8.21001H11.1846V14.21C11.1846 14.7623 10.7368 15.21 10.1846 15.21C9.63232 15.21 9.18457 14.7623 9.18457 14.21V8.21001H6.00171C5.20081 8.21001 4.72485 7.31557 5.17224 6.65134L9.35522 0.44134ZM2 11C2 10.4477 1.55225 10 1 10C0.447754 10 0 10.4477 0 11V19C0 19.5523 0.447754 20 1 20H19C19.5522 20 20 19.5523 20 19V11C20 10.4477 19.5522 10 19 10C18.4478 10 18 10.4477 18 11V18H2V11Z' />
    </svg>
  </Icon>
)
