import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='times'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0.305533 0.395037C-0.101844 0.80243 -0.101844 1.46297 0.305533 1.87036L8.43518 9.99999L0.305533 18.1297C-0.101844 18.537 -0.101844 19.1976 0.305533 19.605L0.395057 19.6945C0.802434 20.1018 1.46297 20.1018 1.87035 19.6945L9.99999 11.5648L18.1297 19.6945C18.537 20.1018 19.1976 20.1018 19.605 19.6945L19.6945 19.605C20.1018 19.1976 20.1018 18.537 19.6945 18.1297L11.5648 9.99999L19.6945 1.87036C20.1018 1.46297 20.1018 0.80243 19.6945 0.395037L19.605 0.305545C19.1976 -0.101848 18.537 -0.101848 18.1297 0.305545L9.99999 8.43518L1.87035 0.305545C1.46297 -0.101848 0.802434 -0.101848 0.395057 0.305545L0.305533 0.395037Z' />
    </svg>
  </Icon>
)
