import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='save'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M2 2V18H18V7.82843L12.1716 2H11V4C11 4.55229 10.5522 5 10 5H4C3.44775 5 3 4.55229 3 4V2H2ZM7 2H9V4H7V2ZM12.5858 0H10H4H1C0.447754 0 0 0.447715 0 1V19C0 19.5523 0.447754 20 1 20H19C19.5522 20 20 19.5523 20 19V7.41421C20 7.149 19.8947 6.89464 19.7072 6.70711L13.2928 0.292893C13.1053 0.105357 12.851 0 12.5858 0ZM12 12.5C12 13.8807 10.8807 15 9.5 15C8.11926 15 7 13.8807 7 12.5C7 11.1193 8.11926 10 9.5 10C10.8807 10 12 11.1193 12 12.5ZM14 12.5C14 14.9853 11.9852 17 9.5 17C7.01477 17 5 14.9853 5 12.5C5 10.0147 7.01477 8 9.5 8C11.9852 8 14 10.0147 14 12.5Z' />
    </svg>
  </Icon>
)
