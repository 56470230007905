import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='email'>
    <svg width='20' height='14' viewBox='0 0 20 14' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M4.18545 2L10 6.47604L15.8145 2H4.18545ZM18 2.8416L10.61 8.53043C10.2504 8.80722 9.74957 8.80722 9.39001 8.53043L2 2.84159V12H18V2.8416ZM0 1C0 0.447716 0.447716 0 1 0H1.24706H18.7529H19C19.5523 0 20 0.447716 20 1V13C20 13.5523 19.5523 14 19 14H1C0.447716 14 0 13.5523 0 13V1Z' />
    </svg>
  </Icon>
)
