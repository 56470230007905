
import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='login'>
    <svg width='20' height='17' viewBox='0 0 20 17' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M16.4368 13.1035C19.0713 10.469 19.0713 6.19766 16.4368 3.56318C13.8023 0.928675 9.53093 0.9287 6.89645 3.56318C6.5865 3.87313 6.08401 3.87312 5.77407 3.56318C5.46411 3.25324 5.46411 2.75074 5.77407 2.44078C9.02843 -0.813595 14.3048 -0.813595 17.5592 2.44078C20.8136 5.69516 20.8136 10.9715 17.5592 14.2259C14.3048 17.4803 9.02843 17.4803 5.77407 14.2259C5.46411 13.916 5.46411 13.4135 5.77407 13.1035C6.08402 12.7935 6.58651 12.7935 6.89645 13.1035C9.53093 15.738 13.8023 15.738 16.4368 13.1035ZM14.8723 7.81972C15.3181 8.15302 15.3181 8.82121 14.8723 9.15454L10.3408 12.5427C9.79134 12.9536 9.00851 12.5615 9.00851 11.8753V9.32046H0.833334C0.373097 9.32046 0 8.94738 0 8.48712C0 8.02689 0.373097 7.65379 0.833334 7.65379H9.00851V5.09892C9.00851 4.41278 9.79134 4.02065 10.3408 4.43152L14.8723 7.81972Z' />
    </svg>
  </Icon>
)
