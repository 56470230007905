import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import qs from 'qs'
import UploadSensorSidebar from '../components/upload-sensor-sidebar'
import Head from '../components/head'
import Title from '../components/title'
import File from '../components/file'
import Button from '../components/button'
import UploadIcon from '../components/icons/upload-icon'
import Loading from '../components/loading'

export default ({ history, api }) => {
  const search = qs.parse(history.location.search, { ignoreQueryPrefix: true })
  const id = search.id
  const service = search.service
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = 'Upload Data'

    const onUploadFile = ({ error }) => {
      setUploading(false)
      if (error) setError(error)
      else history.push('/transactions')
    }
    api.on('upload-file-reply', onUploadFile)
    return () => {
      api.off('upload-file-reply', onUploadFile)
    }
  })

  return (
    <div>
      <Head>{id}</Head>
      <div className='columns'>
        <UploadSensorSidebar
          id={id}
          service={service}
          isLoading={false}
          loadingMessage='Getting Data ...'
        />
        <div className='column'>
          <Title>Upload</Title>
          <div className='columns'>
            <div className='column is-4'>
              <File onChange={file => setFile(file)} disabled={uploading} />
            </div>
            <div className='column'>
              <Button
                disabled={file === null || uploading}
                onClick={() => {
                  setUploading(true)
                  api.emit('upload-file', { service, id, file, filename: file.name })
                }}
              >
                <UploadIcon />
                <span>Upload</span>
              </Button>
            </div>
          </div>

          {error ? (
            <div>
              <p className='content has-text-centered'>{error}<br />If this problem persists <Link to='/contact'>please get in touch</Link></p>
            </div>
          ) : uploading ? <Loading>Uploading the file ...</Loading> : null}
        </div>
      </div>
    </div>
  )
}
