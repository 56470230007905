import React from 'react'

import Title from './title'
import InputText from './input-text'
import Button from './button'
import UploadIcon from './icons/upload-icon'
import TimesIcon from './icons/times-icon'

const EditorService = ({ name, networkId, url, registered, leave }) => (
  <div className='service is-editor'>
    <Title>{name}</Title>
    <InputText name='name' label='Name' value={name} readOnly />
    <InputText name='networkId' label='NetworkId' value={networkId} readOnly />
    <InputText name='url' label='URL' value={url} readOnly />
    <div className='columns'>
      <div className='column is-8' />
      <div className='column is-2'>
        {registered
          ? (
            <Button danger className='is-leave'>
              <TimesIcon />
              <span>CANCEL REQUEST</span>
            </Button>
          )
          : null}
      </div>
      <div className='column is-2'>
        <Button danger className='is-leave' onClick={() => leave(name)}>
          <UploadIcon />
          <span>LEAVE</span>
        </Button>
      </div>
    </div>
  </div>
)

export default EditorService
