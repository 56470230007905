import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='cog'>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M4.91063 0.947059L7.19699 2.06508e-05L9.23596 2.80165C9.48107 2.77649 9.72978 2.7636 9.98158 2.7636C10.2449 2.7636 10.505 2.77771 10.761 2.8052L12.8028 0L15.0891 0.947038L14.5467 4.39033C14.9345 4.70736 15.2887 5.06395 15.6032 5.45393L19.0528 4.91054L19.9998 7.19688L17.164 9.2608C17.1875 9.49786 17.1995 9.73832 17.1995 9.98157C17.1995 10.2364 17.1863 10.4882 17.1606 10.7362L20 12.8028L19.0529 15.0891L15.577 14.5416C15.2678 14.9205 14.9208 15.2675 14.5419 15.5767L15.0894 19.0528L12.8031 19.9998L10.7366 17.1606C10.4885 17.1863 10.2366 17.1995 9.98158 17.1995C9.73844 17.1995 9.49808 17.1875 9.26112 17.1641L7.1971 20L4.91075 19.0529L5.45415 15.6034C5.06415 15.2889 4.70755 14.9347 4.39051 14.5468L0.947182 15.0893L0.000144555 12.803L2.80525 10.7613C2.77774 10.5052 2.76362 10.245 2.76362 9.98157C2.76362 9.72987 2.7765 9.48126 2.80164 9.23626L0 7.19719L0.947037 4.9108L4.36386 5.44904C4.68615 5.05011 5.05004 4.68619 5.44898 4.36391L4.91063 0.947059ZM5.08368 9.98157C5.08368 12.6867 7.27649 14.8795 9.98158 14.8795C12.6867 14.8795 14.8795 12.6867 14.8795 9.98157C14.8795 7.27648 12.6867 5.08366 9.98158 5.08366C7.27649 5.08366 5.08368 7.27648 5.08368 9.98157Z' />
    </svg>
  </Icon>
)
