import React from 'react'
import { Link } from 'react-router-dom'
import Head from '../components/head'

const CallbackPage = () => (
  <div>
    <Head>Logged In</Head>
    <p className='content has-text-centered'>
      <span>If this page does not redirect please refresh your browser</span>
      <br />
      <span>If you have any issues please <Link to='/contact'>Contact Us</Link></span>
    </p>
  </div>
)

export default CallbackPage
