import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='reload'>
    <svg width='18' height='20' viewBox='0 0 18 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M8.49634 0.066405C8.17181 -0.149888 7.79987 0.188582 7.80005 0.700057L7.8006 2.58152C3.53558 3.16615 0.25 6.82436 0.25 11.25C0.25 16.0825 4.16748 20 9 20C13.8325 20 17.75 16.0825 17.75 11.25C17.75 10.7897 17.3769 10.4167 16.9167 10.4167C16.4564 10.4167 16.0833 10.7897 16.0833 11.25C16.0833 15.162 12.912 18.3333 9 18.3333C5.08801 18.3333 1.91669 15.162 1.91669 11.25C1.91669 7.74663 4.46008 4.83726 7.80115 4.2677L7.80176 6.30041C7.80188 6.82796 8.19556 7.16508 8.52148 6.91675L12.441 3.92988C12.7969 3.6587 12.7831 2.92354 12.4176 2.67991L8.49634 0.066405Z' />
    </svg>
  </Icon>
)
