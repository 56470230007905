import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='sensor'>
    <svg width='18' height='20' viewBox='0 0 18 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M5.85366 20L9 7.5L10.5 11.7073H13.9196C14.257 12.2905 14.8875 12.6829 15.6098 12.6829C16.6874 12.6829 17.561 11.8094 17.561 10.7317C17.561 9.65407 16.6874 8.78049 15.6098 8.78049C14.8875 8.78049 14.257 9.17287 13.9196 9.7561H11.5L9 0L5.85366 12.6829L4.87805 9.7561H0.975609C0.436795 9.7561 0 10.1929 0 10.7317V10.7317C0 11.2705 0.436795 11.7073 0.975609 11.7073H3.90244L5.85366 20Z' />
    </svg>
  </Icon>
)
