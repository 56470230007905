import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='triangle'>
    <svg width='20' height='11' viewBox='0 0 20 11' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.26711 0.301053C9.66406 -0.100351 10.336 -0.100351 10.7329 0.301053L19.726 9.3944C20.328 10.0032 19.873 11 18.9931 11H1.00693C0.126986 11 -0.328018 10.0032 0.274048 9.3944L9.26711 0.301053Z' transform='translate(20 11) rotate(-180)' />
    </svg>
  </Icon>
)
