import React from 'react'
import Button from './button'

export default ({
  active,
  title,
  children,
  buttons = [],
  onDismiss = () => {}
}) => {
  return (
    <div className={`modal ${active ? 'is-active' : ''}`}>
      <div className='modal-background' onClick={onDismiss} />
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{title}</p>
          <button className='delete' aria-label='close' onClick={onDismiss} />
        </header>
        <section className='modal-card-body'>
          {children}
        </section>
        {buttons.length > 0 ? (
          <footer className='modal-card-foot'>
            <div className='columns'>
              {buttons.map((button, index) => (
                <div key={`modal-button-${index}`} className='column'>
                  <Button onClick={() => button.onClick()} danger={button.danger}>
                    {button.icon}
                    <span>{button.label}</span>
                  </Button>
                </div>
              )
              )}
            </div>
          </footer>
        ) : null}
      </div>
    </div>
  )
}
