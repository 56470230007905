import React from 'react'
import Icon from './icon'

export default () => (
  <Icon name='map-point'>
    <svg width='14' height='20' viewBox='0 0 14 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M6.89655 0C3.08769 0 0 3.08769 0 6.89655C0 8.14635 0.33243 9.31841 0.91369 10.3293C1.42957 11.3697 2.74526 13.435 4.22243 15.7538C4.81535 16.6845 5.43432 17.6562 6.03801 18.6188C6.43403 19.2502 7.35889 19.2502 7.75494 18.6188C8.35865 17.6563 8.97764 16.6847 9.57055 15.7539C11.0477 13.4352 12.3635 11.3697 12.8794 10.3293C13.4607 9.31841 13.7931 8.14635 13.7931 6.89655C13.7931 3.08769 10.7054 0 6.89655 0ZM6.89655 8.27586C8.42007 8.27586 9.65517 7.04076 9.65517 5.51724C9.65517 3.9937 8.42007 2.75862 6.89655 2.75862C5.37301 2.75862 4.13793 3.9937 4.13793 5.51724C4.13793 7.04076 5.37301 8.27586 6.89655 8.27586Z' />
    </svg>
  </Icon>
)
